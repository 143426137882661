.dropcap p:first-of-type::first-letter {
  float: left;
  font-size: 4em;
  /* Adjust as needed */
  line-height: 0.8;
  /* Adjust as needed */
  margin-right: 0.1em;
  /* Adjust as needed */

  color: #333;
  /* Adjust color as needed */
}
.title {
  font-size: 30px;
  color: darkorange;
  text-transform: uppercase;
  vertical-align: middle;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  letter-spacing: 2px;
}

.icon {
  margin-right: 10px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card .header-image {
  height: 200px;
  width:fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.card img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.card .content {
  padding: 20px 0;
}

.card .content h3 {
  font-size: 24px;
  color: #f1560e;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-family: "Arial", sans-serif;
}

.card .content p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.main-content--section {
  padding-top: 20px;
  text-align: justify;
}

.icon {
  display: inline-block;
  padding: 5px;
  border: 2px solid darkorange;
  border-radius: 5px;
  background-color: darkorange;
  color: white;
}

.card span {
  font-weight: bold; /* Make span text bold */
}
.posts--filter-bar {
  margin-top: 20px;
}

.nav li.active a {
  color: #f1560e; /* Change this to the desired color */
}