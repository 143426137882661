@media screen and (max-width:1199px) {
  .header--logo {
    max-width: 400px;
    margin: 0 auto;
  }

  .header--style-5 .header--logo {
    max-width: none;
    margin: 0 auto;
  }
}

@media screen and (min-width:992px) and (max-width:1199px) {
  .social--widget.style--2 .nav>li,.social--widget.style--7 .nav>li {
    width: 100%;
  }

  body.boxed>.wrapper {
    max-width: 1000px;
  }

  .header--style-6 .header--navbar-inner {
    padding-left: 4px;
    padding-right: 4px;
  }

  .header--menu-links.nav>li>a {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

@media screen and (min-width:992px) {
  .row--md-vc>div {
    float: none;
    display: table-cell;
    vertical-align: middle;
  }
}

@media screen and (max-width:991px) {
  body.boxed>.wrapper {
    max-width: 100%;
  }

  .float--sm-none,.header--menu-links>li,.header--style-4 .navbar-header {
    float: none;
  }

  .text-sm-center {
    text-align: center;
  }

  .comment--items>li>.comment--items {
    padding-left: 0;
  }

  .header--logo {
    max-width: none;
    margin: 0 auto;
  }

  .header--logo a {
    height: auto;
  }

  .header--style-4 .header--logo a,.header--style-5 .header--logo a {
    height: 90px;
  }

  .header--ad {
    margin-top: 17px;
  }

  .header--navbar .navbar-toggle {
    display: block;
  }

  .header--style-4 .navbar-toggle {
    position: absolute;
    top: 0;
    left: 15px;
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .header--navbar .navbar-collapse {
    display: block!important;
    position: fixed;
    top: 0;
    left: -300px;
    bottom: 0;
    width: 250px;
    height: auto!important;
    margin: 0;
    color: #777;
    background-color: #fff;
    border-width: 0;
    box-shadow: 0 3px 7px rgba(0,0,0,.2);
    overflow: visible!important;
    -webkit-transition: left .55s ease-in-out;
    transition: left .55s ease-in-out;
    z-index: 999;
  }

  .header--navbar .navbar-collapse:before {
    content: "\f00d";
    position: absolute;
    top: 0;
    right: -40px;
    width: 40px;
    color: #fff;
    background-color: #da0000;
    box-shadow: 1px 3px 5px rgba(0,0,0,.2);
    font-family: "FontAwesome";
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    z-index: 9999;
  }

  .header--navbar .navbar-collapse.in {
    left: 0;
  }

  .header--menu-links {
    float: none;
    height: 100%;
    margin: 0;
    padding-bottom: 5px;
    overflow: auto;
  }

  .header--menu-links>li>a,.header--style-4 .header--menu-links>li>a {
    padding-top: 12px;
    padding-bottom: 10px;
  }

  .header--menu-links>.dropdown>.dropdown-menu {
    float: none;
    position: relative!important;
    border-bottom-width: 0;
    box-shadow: none;
  }

  .header--menu-links>.megamenu>.dropdown-menu {
    left: 0;
    right: 0;
    padding: 0;
    border-bottom-width: 1px;
  }

  .header--menu-links>.megamenu.filter>.dropdown-menu,.header--menu-links>.megamenu.posts>.dropdown-menu {
    border-bottom-width: 0;
  }

  #stickySocial,.header--menu-links>.megamenu.filter>.dropdown-menu,.header--menu-links>.megamenu.filter>.dropdown-toggle>i,.header--menu-links>.megamenu.posts>.dropdown-menu,.header--menu-links>.megamenu.posts>.dropdown-toggle>i {
    display: none;
  }

  .header--menu-links>.megamenu>.dropdown-menu>.dropdown {
    float: none;
    margin: 0;
    width: 100%;
  }

  .header--menu-links>.megamenu>.dropdown-menu>.dropdown>.dropdown-menu>li>a,.header--menu-links>.megamenu>.dropdown-menu>.dropdown>a {
    padding-left: 20px;
    padding-right: 20px;
  }

  .header--menu-links>.megamenu>.dropdown-menu>li>.row {
    margin: 0;
  }

  .header--menu-links>.megamenu>.dropdown-menu>li>.row>div {
    padding: 0;
  }

  .header--navbar .dropdown-menu>.dropdown>.dropdown-menu {
    float: none;
    position: relative;
    top: 1px;
    left: 0;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    box-shadow: none;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  .header--navbar .dropdown-menu>.dropdown>.dropdown-menu>li>a {
    padding-left: 30px;
    padding-right: 30px;
  }

  .header--style-4 .header--search-form {
    position: absolute;
    top: 0;
    right: 15px;
  }

  .post--item .col-md-6>.post--info,.post--item .col-md-8>.post--info {
    margin-top: 15px;
  }

  .product--single .tab-nav,.product--single .tab-nav>li {
    display: block;
  }

  .product--single ul.tab-nav>li>a {
    border-width: 1px;
  }

  .product--single ul.tab-nav>li+li>a {
    border-top-width: 0;
  }

  .contact--cats .nav>li:nth-child(3) {
    padding-top: 18px;
    border-top-width: 1px;
  }

  .contact--cats .nav>li:nth-child(3n) {
    padding-right: 18px;
    border-right-width: 1px;
  }

  .contact--cats .nav>li:nth-child(3n)+li {
    padding-left: 18px;
  }

  .contact--cats .nav>li:nth-child(2n) {
    padding-right: 0;
    border-right-width: 0;
  }

  .contact--cats .nav>li:nth-child(2n)+li {
    padding-left: 0;
  }
}

@media screen and (max-width:600px) {
  .col-xss-12 {
    float: none;
    width: 100%;
    margin-left: 0;
  }

  .hidden-xss {
    display: none!important;
  }

  .shown-xss {
    display: block!important;
  }
}

@media screen and (max-width:767px) {
  .float--xs-none {
    float: none;
  }

  .text-xs-center {
    text-align: center;
  }

  .list--widget-nav>.nav>li+li {
    border-top-width: 0;
    border-left-width: 1px;
  }

  .header--menu-links>.dropdown>.dropdown-menu>li>a,.navbar-nav .open .dropdown-menu>li>a {
    padding-top: 9px;
    padding-bottom: 10px;
  }

  .header--navbar .navbar-header {
    float: left;
    margin-left: 0;
  }

  .header--style-4 .navbar-header,.header--style-5 .navbar-header,.news--ticker .title {
    float: none;
  }

  .news--ticker .title,.news-updates--list {
    padding-left: 15px;
    padding-right: 15px;
  }

  .news-updates--list:after,.news-updates--list:before {
    width: 15px;
  }

  .post--item .col-sm-12>.post--info {
    margin-top: 0;
  }

  .cart--items .table {
    border: 0;
  }

  .cart--items .table thead {
    display: none;
  }

  .cart--items .table tbody tr {
    display: block;
  }

  .cart--items .table tbody tr+tr {
    margin-top: 30px;
  }

  .cart--items .table tbody tr td {
    display: block;
    position: relative;
    padding-left: 152px;
    border-width: 1px 1px 0;
    text-align: right;
  }

  .cart--items .table tbody tr td:last-child {
    border-bottom-width: 1px;
  }

  .cart--items .table tbody tr td:before {
    content: attr(data-label);
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -14px;
    color: #222;
    font-family: "Lora",serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
  }

  .cart--items .footer {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 30px;
    border-top-width: 1px;
    text-align: center;
  }

  .cart--items .footer .float--left,.cart--items .footer .float--right {
    float: none;
  }

  .cart--items .footer .coupon--code {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .contact--info {
    text-align: center;
  }

  .f0f--content,.f0f--content:before {
    background-color: rgba(0,0,0,.5);
  }

  .f0f--content:after {
    border-color: transparent transparent rgba(0,0,0,.8) rgba(0,0,0,.8);
  }
}

@media screen and (max-width:480px) {
  .hidden-xxs {
    display: none!important;
  }

  .shown-xxs {
    display: block!important;
  }

  .col-xxs-12 {
    float: none;
    width: 100%;
    margin-left: 0;
  }

  .text-xxs-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .pagination--wrapper {
    text-align: center;
  }

  .pagination-hint {
    width: 100%;
    margin-bottom: 5px;
  }

  .pagination,.pagination-hint {
    display: inline-block;
    float: none;
  }

  .header--topbar-info>li {
    float: none!important;
    display: inline-block;
  }

  .post--item.post--layout-1.post--type-video .post--info .title .h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .post--item .col-xxs-12>.post--info {
    margin-top: 12px;
  }

  .login--form .help-block .btn-link {
    float: none!important;
    display: block;
  }

  .contact--cats .nav>li {
    min-height: 0!important;
    padding-left: 0;
    padding-right: 0;
    border-width: 0 0 1px!important;
  }

  .contact--cats .nav>li:nth-child(2),.contact--cats .nav>li:nth-child(3) {
    padding-top: 18px;
  }

  .contact--cats .nav>li:nth-child(3n)+li {
    padding-left: 0;
  }

  .contact--cats .nav>li:nth-child(3n) {
    padding-right: 0;
  }

  .contact--cats .nav>li:last-child {
    border-bottom-width: 0!important;
  }

  .footer--copyright {
    text-align: center;
  }

  .footer--copyright .social,.footer--copyright .social--bg {
    display: none;
  }

  .footer--copyright .float--left,.footer--copyright .float--right {
    float: none;
  }

  .footer--copyright .text {
    padding-bottom: 8px;
  }

  .footer--copyright .links {
    display: inline-block;
    padding-top: 8px;
  }
}

@media screen and (max-width:380px) {
  .header--style-4 .header--logo .h1,.header--style-5 .header--logo .h1 {
    max-width: 200px;
    margin: 0 auto;
  }

  .post--item.post--layout-1 .post--info .title .h4 {
    max-height: 54px;
    overflow: hidden;
  }

  .post--author-info {
    padding-left: 30px;
  }

  .post--author-info .img {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: -28px -31px 27px;
    text-align: center;
  }
}
