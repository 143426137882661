/* #########################################################
HOW TO CREATE A RESPONSIVE IMAGE SLIDER [TUTORIAL]
"How to create a Responsive Image Slider [Tutorial]" was specially made for DesignModo by our friend Valeriu Timbuc.
Links:
http://vtimbuc.net
http://designmodo.com
http://vladimirkudinov.com
######################################################### */
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus  { outline: none; }
.slides,
.flex-control-nav,
.flex-direction-nav {
	margin: 0;
	padding: 0;
	list-style: none;
}
.flexslider a img { outline: none; border: none; }
.flexslider {
	margin: 0;
	padding: 0;
}
/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides > li {
	/*display: none;*/
	-webkit-backface-visibility: hidden;
}
.flexslider .slides img {
	width: 100%;
	display: block;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	box-shadow:#999 0 0 10px;
}
/* Clearfix for the .slides element */
.slides:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
html[xmlns] .slides { display: block; }
* html .slides { height: 1%; }
/* Theme Styles */
.flexslider {
	position: relative;
	zoom: 1;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
/* Edit it if you want */
.flex-container {
	min-width: 150px;
	max-width: 960px;
}
.flexslider .slides { zoom: 1; }
/* Direction Nav */
.flex-direction-nav a {
	display: block;
	position: absolute;
	margin: -17px 0 0 0;
	width: 44px;
	height: 44px;
	top: 50%;
	cursor: pointer;
	text-indent: -9999px;
	z-index: 9999;
	/*background-color: #d9d9d9;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#d9d9d9), to(#d9d9d9));
	background-image: -webkit-linear-gradient(top, #d9d9d9, #d9d9d9);
	background-image: -moz-linear-gradient(top, #d9d9d9, #d9d9d9);
	background-image: -o-linear-gradient(top, #d9d9d9, #d9d9d9);
	background-image: linear-gradient(to bottom, #d9d9d9, #d9d9d9);*/
}
.flex-direction-nav a:hover{
	/*background-color: #b7b6b6;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#b7b6b6), to(#b7b6b6));
	background-image: -webkit-linear-gradient(top, #b7b6b6, #b7b6b6);
	background-image: -moz-linear-gradient(top, #b7b6b6, #b7b6b6);
	background-image: -o-linear-gradient(top, #b7b6b6, #b7b6b6);
	background-image: linear-gradient(to bottom, #b7b6b6, #b7b6b6);*/
}
.flex-direction-nav a:before {
	display: block;
	position: absolute;
	content: '';
	width: 9px;
	height: 13px;
	top: 11px;
	left: 11px;
	/*background: url(arrows.png) no-repeat;*/	
}
.flex-direction-nav a:after {
	display: block;
	position: absolute;
	content: '';
	width: 0;
	height: 0;
	top: 35px;
}

@media (max-width: 992px) {
.flex-direction-nav .flex-next {
	display:none;
	visibility:hidden;
	right: -75px;
	/*right:5px;*/
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
}
.flex-direction-nav .flex-prev {
	display:none;
	visibility:hidden;
	left: -75px;
	/*left:5px;*/
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
}
}
.flex-direction-nav .flex-next:before { background-position: -9px 0; left: 15px; }
.flex-direction-nav .flex-prev:before { background-position: 0 0; }
.flex-direction-nav .flex-next:after {
	right: 0;
	/*border-bottom: 5px solid transparent;
	border-left: 5px solid #31611e;*/
}
.flex-direction-nav .flex-prev:after {
	left: 0;
	/*border-bottom: 5px solid transparent;
	border-right: 5px solid #31611e;*/
}
/* Control Nav */
.flexslider .flex-control-nav {
	position: absolute;
	width: 100%;
	bottom: -40px;
	text-align: center;
	margin: 0 0 0 -10px;
}
.flex-control-nav li {
	display: inline-block;
	zoom: 1;
}
.flex-control-paging li a {
	display: block;
	cursor: pointer;
	text-indent: -9999px;
	width: 12px;
	height: 12px;
	margin: 0 3px;
	background-color: #b6b6b6 \9;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
	-webkit-box-shadow: inset 0 0 0 2px #b6b6b6;
	-moz-box-shadow: inset 0 0 0 2px #b6b6b6;
	box-shadow: inset 0 0 0 2px #b6b6b6;
}
.flex-control-paging li a.flex-active {
	background-color: #980202;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#980202), to(#980202));
	background-image: -webkit-linear-gradient(top, #980202, #980202);
	background-image: -moz-linear-gradient(top, #980202, #980202);
	background-image: -o-linear-gradient(top, #980202, #980202);
	background-image: linear-gradient(to bottom, #980202, #980202);
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
/* Captions */
.flexslider .slides p {
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	/*padding: 0 5px;*/
	padding:20px;
	margin: 0;
	width:100%;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 20px;
	color: white;
	background-color: #222222;
	background: rgba(0,0,0, .6);
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
}