$layout-mobile: 600px;
$layout-min-web: 900px;

// regular style toast
@import 'ngx-toastr/toastr';

// @import "../node_modules/ag-grid-community/styles/ag-grid.css";
// @import "../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
// @import "../node_modules/ag-grid-community/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

@import url("./assets/css/css-open-sans.css");
@import url("./assets/css/css-roboto-family.css");
@import url("./assets/css/icon.css");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import url("./assets/css/bootstrapcss/bootstrap.css");
@import url("./assets/css/bootstrap.min.css");

.loader {
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $layout-mobile) {
        img {
            height: 13%;
        }
    }

    @media (min-width: $layout-min-web) {
        img {
            height: 100px;
        }
    }

    .progress-container {
        overflow: hidden;
        width: 25%;
        height: 4px;
        background-color: #b3e5fc;
        margin: 25px auto;
    }

    .indeterminate {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .indeterminate::before {
        content: '';
        position: absolute;
        height: 100%;
        background-color: #03a9f4;
        animation: indeterminate_first 1.5s infinite ease-out;
    }

    .indeterminate::after {
        content: '';
        position: absolute;
        height: 100%;
        background-color: #4fc3f7;
        animation: indeterminate_second 1.5s infinite ease-in;
    }

    @keyframes indeterminate_first {
        0% {
            left: -100%;
            width: 100%;
        }

        100% {
            left: 100%;
            width: 10%;
        }
    }
}

.code-value-snackbar {
    background: #194A82;

    button {
        background: #194A82;
        color: white;
    }
}

.error-text {
    font-size: 12px;
    color: rgb(202, 23, 23);
}

.mb-20 {
    margin-bottom: 20px !important;
}


.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #194A82;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #194A82 !important;
}

.mat-form-field-outline {
    color: #A99C9C !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 2px !important;
}


.heart-rate {
    width: 150px;
    height: 73px;
    position: relative;
    /* margin: 20px auto; */
}

.fade-in {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    top: 0;
    right: 0;
    animation: heartRateIn 2.5s linear infinite;
}

.fade-out {
    position: absolute;
    width: 120%;
    height: 100%;
    top: 0;
    left: -120%;
    animation: heartRateOut 2.5s linear infinite;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(left, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(left, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(left, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 80%, rgba(255, 255, 255, 0) 100%);
}

@keyframes heartRateIn {
    0% {
        width: 100%;
    }

    50% {
        width: 0;
    }

    100% {
        width: 0;
    }
}

@keyframes heartRateOut {
    0% {
        left: -120%;
    }

    30% {
        left: -120%;
    }

    100% {
        left: 0;
    }
}