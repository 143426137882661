/* body {
  color: #777;
  font-family: "Source Sans Pro",sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  word-wrap: break-word;
} */
p{
	font-size:16px;
	line-height:19px;
	color:#666;
	text-align:justify;
}

.table:last-child,p:last-child,ul:last-child {
  margin-bottom: 0;
}

.nav>li>a>img,img {
  max-width: 100%;
  height: auto;
}

::-moz-selection {
  color: #fff;
  background-color: #da0000;
}

::selection {
  color: #fff;
  background-color: #da0000;
}

.del {
  text-decoration: line-through;
}

.sa_svg-hidden,.sa_svg-hidden svg {
  width: 0;
  height: 0;
  overflow: hidden;
}

[data-zoom=img] {
  display: block;
  overflow: hidden;
}

a {
  color: #da0000;
  -webkit-transition-property: color,border-color,background-color,box-shadow;
  transition-property: color,border-color,background-color,box-shadow;
  -webkit-transition-duration: .25s;
  transition-duration: .25s;
  -webkit-transition-timing-function: ease,ease,ease-in-out,ease-in-out;
  transition-timing-function: ease,ease,ease-in-out,ease-in-out;
}

.btn-link.active,.btn-link:focus,.btn-link:hover,a:focus,a:hover {
  color: #da0000;
}

.btn-link {
  display: inline-block;
  color: inherit;
  font-weight: inherit;
  -webkit-transition: color .25s,border-color .25s,background-color .25s ease-in-out;
  transition: color .25s,border-color .25s,background-color .25s ease-in-out;
  cursor: pointer;
}

.btn-link,.btn-link:active,.btn-link:focus,.btn-link:hover,.btn-link:link,.btn-link:visited,a,a:active,a:focus,a:hover,a:link,a:visited {
  text-decoration: none;
  outline: 0;
}

.nav li a:focus,.nav li a:hover,.nav li.open a,.nav li.open a:focus,.nav li.open a:hover {
  background-color: transparent;
}

.nav li a {
  color: inherit;
}

.nav li a:focus {
  color: inherit;
}

.nav li a:hover,.nav li.active>a,.nav li.active>a:focus,.nav li.active>a:hover,.nav li.open>a,.nav li.open>a:focus,.nav li.open>a:hover {
  color: #da0000;
}

.btn {
  display:inline-block !important;
  padding: 2px 7px;
  border-radius: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  /*-webkit-transition: color .25s,border-color .25s,background-color .25s ease-in-out;
  transition: color .25s,border-color .25s,background-color .25s ease-in-out;*/
}

.btn.active,.btn:active {
  box-shadow: none;
}

.btn.active.focus,.btn.active:focus,.btn.focus,.btn:active.focus,.btn:active:focus,.btn:focus {
  outline: 0;
}

.btn-sm {
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
  line-height: 22px;
}

.btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
}

.btn-lg {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 16px;
  line-height: 26px;
}

/*.btn-default,.btn-default.disabled.focus,.btn-default.disabled:focus,.btn-default.disabled:hover,.btn-default[disabled].focus,.btn-default[disabled]:focus,.btn-default[disabled]:hover {
  color: #999;
  background-color: transparent;
  background-color:inherit;
}

.btn-default.active,.btn-default.active.focus,.btn-default.active:focus,.btn-default.active:hover,.btn-default.focus,.btn-default:active,.btn-default:active.focus,.btn-default:active:focus,.btn-default:active:hover,.btn-default:focus,.btn-default:hover,.open>.dropdown-toggle.btn-default,.open>.dropdown-toggle.btn-default.focus,.open>.dropdown-toggle.btn-default:focus,.open>.dropdown-toggle.btn-default:hover {
  color: #fff;
  background-color: #da0000;
  border-color: #da0000;
}

.btn-primary,.btn-primary.disabled.focus,.btn-primary.disabled:focus,.btn-primary.disabled:hover,.btn-primary[disabled].focus,.btn-primary[disabled]:focus,.btn-primary[disabled]:hover {
  background-color: #da0000;
  border-color: #da0000;
}

.btn-primary.active,.btn-primary.active.focus,.btn-primary.active:focus,.btn-primary.active:hover,.btn-primary.focus,.btn-primary:active,.btn-primary:active.focus,.btn-primary:active:focus,.btn-primary:active:hover,.btn-primary:focus,.btn-primary:hover,.open>.dropdown-toggle.btn-primary,.open>.dropdown-toggle.btn-primary.focus,.open>.dropdown-toggle.btn-primary:focus,.open>.dropdown-toggle.btn-primary:hover {
  background-color: #222;
  border-color: #222;
}

.bg--overlay .btn-primary:focus,.bg--overlay .btn-primary:hover {
  color: #222;
  background-color: #fff;
  border-color: #fff;
}*/

/* .form-control {
  height: 36px;
  padding: 4px 20px;
  background-color: transparent;
  border-color: #f9f9f9;
  border-radius: 0;
  box-shadow: none;
  font-size: 16px;
  line-height: 26px;
  -webkit-transition: color .25s,border-color .25s,background-color .25s ease-in-out;
  transition: color .25s,border-color .25s,background-color .25s ease-in-out;
  outline: 0;
}

.form-control:focus {
  border-color: #da0000;
  box-shadow: none;
} */

/* textarea.form-control {
  height: auto;
  min-height: 100px;
  resize: vertical;
} */

select.form-control {
  cursor: pointer;
}

/* .input-group-btn:last-child>.btn,.input-group-btn:last-child>.btn-group {
  margin-left: 0;
} */

body.boxed {
  background-size: auto;
  background-repeat: repeat;
}

body.boxed>.wrapper {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

.float--left {
  float: left;
}

.float--right {
  float: right;
}

.float--none {
  float: none;
}

.mtop--30 {
  margin-top: 30px;
}

.fm {
  margin-right: 5px;
}

.flm {
  margin-left: 5px;
}

.ptop--15 {
  padding-top: 15px;
}

.ptop--30 {
  padding-top: 30px;
}

.ptop--60 {
  padding-top: 60px;
}

.ptop--70 {
  padding-top: 70px;
}

.pbottom--30 {
  padding-bottom: 30px;
}

.pbottom--60 {
  padding-bottom: 60px;
}

.pd--30-0 {
  padding: 30px 0;
}

.pd--100-0 {
  padding: 100px 0;
}

.pd--150-0 {
  padding: 150px 0;
}

.pd--30-0-20 {
  padding: 30px 0 20px;
}

.pd--20-0-40 {
  padding: 20px 0 40px;
}

.bdtop--1 {
  border-top: 1px solid;
}

.text--color-1 {
  color: #da0000;
}

.bg--skew-down,.bg--skew-up {
  position: relative;
  z-index: 0;
}

.bg--skew-down:after,.bg--skew-up:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 150px;
  background-color: transparent;
  -webkit-transform: skewY(-4deg);
  transform: skewY(-4deg);
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  z-index: -1;
}

.bg--skew-down:after {
  top: auto;
  bottom: 0;
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
}

.bg--color-0,.bg--color-0--a:after,.bg--color-0--b:before,.bg--color-0.bg--skew-down:after,.bg--color-0.bg--skew-up:before {
  color: #222;
  background-color: #fff;
}

.bg--color-1,.bg--color-1--a:after,.bg--color-1--b:before,.bg--color-1.bg--skew-down:after,.bg--color-1.bg--skew-up:before {
  color: #fff;
  background-color: #da0000;
}

.bg--color-2,.bg--color-2--a:after,.bg--color-2--b:before,.bg--color-2.bg--skew-down:after,.bg--color-2.bg--skew-up:before {
  color: #fff;
  background-color: #d6cdac;
  /*background-color: #505559;*/
}

.bg--color-3,.bg--color-3--a:after,.bg--color-3--b:before,.bg--color-3.bg--skew-down:after,.bg--color-3.bg--skew-up:before {
  color: #fff;
  background-color: #340000;
}

.bg--color-4,.bg--color-4--a:after,.bg--color-4--b:before,.bg--color-4.bg--skew-down:after,.bg--color-4.bg--skew-up:before {
  background-color: #f9f9f9;
}

.bg--color-5{
	color:#FFF;
	background:#ffeded !important;
}

.bg--img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg--overlay {
  position: relative;
  color: #fff;
  z-index: 0;
}

.bg--overlay:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  z-index: -1;
}

.bd--color-1 {
  border-color: #da0000;
}

.bd--color-2 {
  border-color: #eee;
}

.gutter--0 {
  margin-left: 0;
  margin-right: 0;
}

.gutter--0>[class*=col-] {
  padding-left: 0;
  padding-right: 0;
}

.gutter--10 {
  margin-left: -5px;
  margin-right: -5px;
}

.gutter--10>[class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}

.gutter--15 {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.gutter--15>[class*=col-] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.gutter--20 {
  margin-left: -10px;
  margin-right: -10px;
}

.gutter--20>[class*=col-] {
  padding-left: 10px;
  padding-right: 10px;
}

.vc--parent {
  width: 100%;
  height: 100%;
  display: table;
}

.row--vb>div,.row--vc>div,.vc--child {
  display: table-cell;
  vertical-align: middle;
}

.vc--child-bottom {
  display: table-cell;
}

.row--vb>div,.row--vc>div {
  float: none;
}

.row--vb>div,.vc--child-bottom {
  vertical-align: bottom;
}

#preloader,.preloader,.preloader:before {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
}

.preloader,.preloader:before {
  position: absolute;
  z-index: 0;
}

.preloader:before {
  content: " ";
  z-index: -1;
}

.preloader--inner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.preloader[data-preloader="1"] .preloader--inner,.preloader[data-preloader="2"] .preloader--inner,.preloader[data-preloader="3"] .preloader--inner {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

.preloader[data-preloader="1"] .preloader--inner:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #da0000;
  border-radius: 50%;
  -webkit-animation: preloaderScaleout 1s infinite ease-in-out;
  animation: preloaderScaleout 1s infinite ease-in-out;
}

.preloader.bg--color-1--b[data-preloader="1"] .preloader--inner:before {
  background-color: #fff;
}

.preloader[data-preloader="2"] .preloader--inner:after,.preloader[data-preloader="2"] .preloader--inner:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #da0000;
  border-radius: 50%;
  opacity: .6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  -webkit-animation: preloaderBounce 2s infinite ease-in-out;
  animation: preloaderBounce 2s infinite ease-in-out;
}

.preloader[data-preloader="2"] .preloader--inner:after {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.preloader[data-preloader="3"] .preloader--inner {
  -webkit-animation: preloaderRotate 2s linear infinite;
  animation: preloaderRotate 2s linear infinite;
}

.preloader[data-preloader="3"] .preloader--inner:after,.preloader[data-preloader="3"] .preloader--inner:before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 60%;
  height: 60%;
  background-color: #da0000;
  border-radius: 100%;
  -webkit-animation: preloaderBounce 2s infinite ease-in-out;
  animation: preloaderBounce 2s infinite ease-in-out;
}

.preloader[data-preloader="3"] .preloader--inner:after {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.preloader[data-preloader="4"] .preloader--inner {
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  background-color: #da0000;
  -webkit-animation: preloaderRotateplane 1.2s ease-in-out infinite;
  animation: preloaderRotateplane 1.2s ease-in-out infinite;
}

.preloader[data-preloader="5"] .preloader--inner {
  width: 32px;
  height: 32px;
  margin-top: -32px;
  margin-left: -32px;
}

.preloader[data-preloader="5"] .preloader--inner:after,.preloader[data-preloader="5"] .preloader--inner:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #da0000;
  -webkit-animation: preloaderCubemove 1.8s ease-in-out infinite;
  animation: preloaderCubemove 1.8s ease-in-out infinite;
}

.preloader[data-preloader="5"] .preloader--inner:after {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

.preloader[data-preloader="6"] .preloader--inner {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

.preloader[data-preloader="6"] .preloader--inner span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}

.preloader[data-preloader="6"] .preloader--inner span:before {
  content: " ";
  display: block;
  width: 15%;
  height: 15%;
  background-color: #da0000;
  border-radius: 100%;
  -webkit-animation: preloaderCircleFade 1.2s infinite ease-in-out both;
  animation: preloaderCircleFade 1.2s infinite ease-in-out both;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(2):before,.preloader[data-preloader="7"] .preloader--inner span:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(3):before,.preloader[data-preloader="7"] .preloader--inner span:nth-child(3) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(4):before,.preloader[data-preloader="7"] .preloader--inner span:nth-child(4) {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(5):before,.preloader[data-preloader="7"] .preloader--inner span:nth-child(5) {
  -webkit-animation-delay: -.8s;
  animation-delay: -.8s;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(6):before {
  -webkit-animation-delay: -.7s;
  animation-delay: -.7s;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(7):before,.preloader[data-preloader="8"] .preloader--inner span:nth-child(2) {
  -webkit-animation-delay: -.6s;
  animation-delay: -.6s;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(8):before,.preloader[data-preloader="8"] .preloader--inner span:nth-child(3) {
  -webkit-animation-delay: -.5s;
  animation-delay: -.5s;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(9):before,.preloader[data-preloader="8"] .preloader--inner span:nth-child(4) {
  -webkit-animation-delay: -.4s;
  animation-delay: -.4s;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(10):before {
  -webkit-animation-delay: -.3s;
  animation-delay: -.3s;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(11):before {
  -webkit-animation-delay: -.2s;
  animation-delay: -.2s;
}

.preloader[data-preloader="6"] .preloader--inner span:nth-child(12):before {
  -webkit-animation-delay: -.1s;
  animation-delay: -.1s;
}

.preloader[data-preloader="7"] .preloader--inner,.preloader[data-preloader="8"] .preloader--inner {
  width: 38px;
  height: 30px;
  margin-top: -15px;
  margin-left: -19px;
  font-size: 0;
  line-height: 0;
  text-align: center;
}

.preloader[data-preloader="7"] .preloader--inner span {
  display: inline-block;
  width: 6px;
  height: 100%;
  background-color: #da0000;
  -webkit-animation: preloaderStretchdelay 1.2s ease-in-out infinite;
  animation: preloaderStretchdelay 1.2s ease-in-out infinite;
}

.preloader[data-preloader="7"] .preloader--inner span+span {
  margin-left: 2px;
}

.preloader[data-preloader="8"] .preloader--inner {
  width: 90px;
  margin-left: -45px;
}

.preloader[data-preloader="8"] .preloader--inner span {
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-left: 1.5px;
  margin-right: 1.5px;
  background-color: #da0000;
  border-radius: 50%;
  -webkit-animation: preloaderStretchdelay2 .7s ease-in-out infinite;
  animation: preloaderStretchdelay2 .7s ease-in-out infinite;
}

.preloader[data-preloader="9"] .preloader--inner {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

.preloader[data-preloader="9"] .preloader--inner span {
  float: left;
  display: block;
  width: 33%;
  height: 33%;
  background-color: #da0000;
  -webkit-animation: preloaderCubeGridScale 1.3s infinite ease-in-out;
  animation: preloaderCubeGridScale 1.3s infinite ease-in-out;
}

.preloader[data-preloader="9"] .preloader--inner span:nth-child(1) {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.preloader[data-preloader="9"] .preloader--inner span:nth-child(2) {
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.preloader[data-preloader="9"] .preloader--inner span:nth-child(3) {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

.preloader[data-preloader="9"] .preloader--inner span:nth-child(4) {
  -webkit-animation-delay: .1s;
  animation-delay: .1s;
}

.preloader[data-preloader="9"] .preloader--inner span:nth-child(5) {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.preloader[data-preloader="10"] .preloader--inner span:nth-child(2):before,.preloader[data-preloader="9"] .preloader--inner span:nth-child(6) {
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.preloader[data-preloader="9"] .preloader--inner span:nth-child(7) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.preloader[data-preloader="9"] .preloader--inner span:nth-child(8) {
  -webkit-animation-delay: .1s;
  animation-delay: .1s;
}

.preloader[data-preloader="9"] .preloader--inner span:nth-child(9) {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.preloader[data-preloader="10"] .preloader--inner {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.preloader[data-preloader="10"] .preloader--inner span {
  float: left;
  display: block;
  position: relative;
  width: 50%;
  height: 50%;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.preloader[data-preloader="10"] .preloader--inner span:nth-child(2) {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.preloader[data-preloader="10"] .preloader--inner span:nth-child(3) {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.preloader[data-preloader="10"] .preloader--inner span:nth-child(4) {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.preloader[data-preloader="10"] .preloader--inner span:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #da0000;
  -webkit-animation: preloaderFoldCubeAngle 2.4s infinite linear both;
  animation: preloaderFoldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.preloader[data-preloader="10"] .preloader--inner span:nth-child(3):before {
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
}

.preloader[data-preloader="10"] .preloader--inner span:nth-child(4):before {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

.preloader[data-preloader=img] .preloader--inner {
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

@-webkit-keyframes preloaderScaleout {
  0% {
    -webkit-transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes preloaderScaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@-webkit-keyframes preloaderRotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes preloaderRotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes preloaderRotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }

  to {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes preloaderRotateplane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  to {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@-webkit-keyframes preloaderBounce {
  0%,to {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes preloaderBounce {
  0%,to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes preloaderCubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(.5);
  }

  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }

  75% {
    -webkit-transform: translateX(0) translateY(42px) rotate(-270deg) scale(.5);
  }

  to {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes preloaderCubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(.5);
    transform: translateX(42px) rotate(-90deg) scale(.5);
  }

  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    transform: translateX(42px) translateY(42px) rotate(-179deg);
  }

  50.1% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    transform: translateX(42px) translateY(42px) rotate(-180deg);
  }

  75% {
    -webkit-transform: translateX(0) translateY(42px) rotate(-270deg) scale(.5);
    transform: translateX(0) translateY(42px) rotate(-270deg) scale(.5);
  }

  to {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes preloaderCircleFade {
  0%,39%,to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  40% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}

@keyframes preloaderCircleFade {
  0%,39%,to {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  40% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}

@-webkit-keyframes preloaderStretchdelay {
  0%,40%,to {
    -webkit-transform: scaleY(.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes preloaderStretchdelay {
  0%,40%,to {
    -webkit-transform: scaleY(.4);
    transform: scaleY(.4);
  }

  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-webkit-keyframes preloaderStretchdelay2 {
  0%,40%,to {
    -webkit-transform: translateY(-10px);
  }

  20% {
    -webkit-transform: translateY(-20px);
  }
}

@keyframes preloaderStretchdelay2 {
  0%,40%,to {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  20% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@-webkit-keyframes preloaderCubeGridScale {
  0%,70%,to {
    -webkit-transform: scale3D(1,1,1);
    transform: scale3D(1,1,1);
  }

  35% {
    -webkit-transform: scale3D(0,0,1);
    transform: scale3D(0,0,1);
  }
}

@keyframes preloaderCubeGridScale {
  0%,70%,to {
    -webkit-transform: scale3D(1,1,1);
    transform: scale3D(1,1,1);
  }

  35% {
    -webkit-transform: scale3D(0,0,1);
    transform: scale3D(0,0,1);
  }
}

@-webkit-keyframes preloaderFoldCubeAngle {
  0%,10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  25%,75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  90%,to {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes preloaderFoldCubeAngle {
  0%,10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  25%,75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  90%,to {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.dropdown-menu {
  margin: 0;
  border-width: 0 0 2px;
  border-color: #da0000;
  border-radius: 0;
  box-shadow: 0 1px 15px rgba(0,0,0,.2);
}

.dropdown-menu>li+li {
  border-top: 1px solid #eee;
}

.dropdown-menu>li>a {
  padding-top: 9px;
  padding-bottom: 10px;
}

.dropdown-menu>.dropdown>.dropdown-menu {
  top: 0;
  left: 100%;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.dropdown-menu>.switch--left>.dropdown-menu,.dropdown-menu>.switch--right>.dropdown-menu {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.dropdown-menu>.switch--left>.dropdown-menu {
  left: auto;
  right: 100%;
}

.dropdown-left>.dropdown-menu {
  left: auto;
  right: 0;
}

.pagination {
  margin: -10px -5px 0;
  color: #999;
  font-size: 0;
  line-height: 0;
}

.pagination>li {
  display: inline-block;
  margin: 10px 5px 0;
  font-size: 16px;
  line-height: 26px;
  vertical-align: middle;
}

.pagination>li>a,.pagination>li>span {
  min-width: 36px;
  padding: 0 3px;
  color: #999;
  font-size: 16px;
  line-height: 34px;
  text-align: center;
}

.pagination>li:first-child>a,.pagination>li:first-child>span,.pagination>li:last-child>a,.pagination>li:last-child>span {
  border-radius: 0;
}

.pagination>li.active>span,.pagination>li.active>span:hover,.pagination>li>a:focus,.pagination>li>a:hover,.pagination>li>span:hover {
  color: #fff;
  background-color: #da0000;
  border-color: #da0000;
}

.pagination-hint {
  margin-bottom: 0;
  color: #999;
  font-size: 16px;
  line-height: 36px;
  font-weight: 600;
}

.countdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0;
  line-height: 0;
}

.countdown ul li {
  display: inline-block;
  position: relative;
  margin-right: 42px;
  z-index: 0;
}

.countdown ul li:after,.countdown ul li:before {
  content: " ";
  position: absolute;
  top: 50%;
  right: -24px;
  width: 5px;
  height: 5px;
  margin-top: -4px;
  background-color: #999;
  z-index: 0;
}

.countdown ul li:after {
  margin-top: 7px;
}

.countdown ul li:last-child strong:after,.countdown ul li:last-child strong:before,.countdown ul li:last-child:after,.countdown ul li:last-child:before {
  display: none;
}

.countdown ul li strong {
  display: block;
  font-size: 48px;
  line-height: 52px;
  font-weight: 400;
}

.countdown ul li strong:after,.countdown ul li strong:before {
  content: " ";
  position: absolute;
  top: 14px;
  right: -22px;
  width: 1px;
  height: 13px;
  background-color: #555;
}

.countdown ul li strong:after {
  top: auto;
  bottom: 5px;
}

.countdown ul li span {
  display: block;
  margin-top: 1px;
}

.post--items-title+.comment--items {
  margin-top: 10px;
}

.comment--items>li+li,.comment--items>li>.comment--items {
  margin-top: 20px;
}

.comment--items>li>.comment--items {
  padding-left: 30px;
}

.comment--img {
  max-width: 60px;
  margin-right: 20px;
  border: 2px solid #ccc;
}

.comment--info {
  padding: 16px 20px 3px;
  background-color: #f2f3f5;
  overflow: hidden;
}

.comment--header {
  margin-bottom: 8px;
  color: #999;
  line-height: 18px;
}

.comment--header .name {
  float: left;
  margin-bottom: 0;
  color: #222;
  font-weight: 600;
}

.comment--header .date {
  float: left;
  margin-left: 15px;
  margin-bottom: 0;
  padding-left: 14px;
  border-left: 1px solid #ccc;
}

.comment--header .reply {
  float: right;
  display: block;
}

.comment--header .rating {
  float: right;
  color: #da0000;
  font-size: 0;
  line-height: 0;
}

.comment--header .rating .nav>li {
  display: inline-block;
}

.comment--header .rating .nav>li+li {
  margin-left: 3px;
}

.comment--header .rating .nav>li>i,.countdown ul li span {
  font-size: 14px;
  line-height: 18px;
}

.comment-respond {
  padding: 23px 30px 30px;
  background-color: #f2f3f5;
}

.comment-respond .status .alert {
  border-radius: 0;
}

.comment--content h1,.comment--content h2,.comment--content h3,.comment--content h4,.comment--content h5,.comment--content h6,.comment-respond p,.post--content h1,.post--content h2,.post--content h3,.post--content h4,.post--content h5,.post--content h6 {
  color: #222;
  font-weight: 600;
}

.comment-respond label {
  color: #777;
  font-weight: 400;
}

.comment-respond label,.comment-respond label span {
  display: block;
  margin-bottom: 8px;
}

.comment-respond .form-control {
  border-color: #ccc;
}

.comment-respond textarea {
  min-height: 188px;
}

.comment-respond .btn {
  margin-top: 22px;
}

.comment-respond .rating {
  margin-top: -1px;
  margin-bottom: 8px;
}

.comment-respond .rating label {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 0;
  color: #222;
  font-weight: 600;
  vertical-align: top;
}

.comment-respond .rating .br-wrapper {
  display: inline-block;
}

.comment-respond .rating .br-widget a {
  display: inline-block;
  margin-right: 9px;
}

.comment-respond .rating .br-widget a:last-child {
  margin-right: 0;
}

.comment-respond .rating .br-widget a:after {
  color: #da0000;
  font-size: 14px;
}

.section--title .h3 {
  margin: -5px 0 -1px;
  color: #222;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.quantity {
  display: inline-block;
  max-width: 60px;
  vertical-align: middle;
}

.quantity .form-control {
  height: 30px;
  padding: 2px 10px;
  border-width: 0;
  text-align: center;
}

.map--fluid {
  min-height: 300px;
}

.main--sidebar .widget+.widget {
  margin-top: 60px;
}

.widget--title {
  position: relative;
  padding: 12px 24px 13px 0;
  border-top: 2px solid #da0000;
  z-index: 0;
}

.widget--title .h4 {
  position: relative;
  margin: 0;
  color: #da0000;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 0;
}

.widget--title .icon,.widget--title .nav {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -13px;
  color: #999;
  font-size: 16px;
  line-height: 26px;
}

.widget--title .nav {
  margin-top: -15px;
  font-size: 0;
  line-height: 0;
}

.widget--title .nav a {
  padding: 0;
  font-size: 16px;
  line-height: 26px;
}

.post--items-title .nav span.divider,.widget--title .nav span.divider {
  display: inline-block;
  margin: 0 5px;
  font-size: 16px;
  line-height: 26px;
}

.bg--color-2 .widget--title {
  border-color: #fff;
}

.bg--color-2 .widget--title .h4,.bg--color-2 .widget--title .icon {
  color: #fff;
}

.ad--widget {
  padding: 30px 30px 15px;
  background-color: #f8f8f8;
  text-align: center;
}

.ad--widget>.row {
  margin: -10px -20px;
}

.ad--widget>.row>[class*=col-] {
  padding-left: 10px;
  padding-right: 10px;
}

.ad--widget a,.social--widget.style--1 .nav>li>a>span {
  display: block;
}

.ad--widget img {
  margin-bottom: 15px;
}

.social--widget .nav {
  padding: 5px 0;
  background-color: #f8f8f8;
  font-size: 0;
  line-height: 0;
}

.social--widget.style--1 .nav>li {
  float: left;
  width: 33.3333%;
}

.social--widget.style--1 .nav>li>a {
  position: relative;
  padding: 15px 15px 8px;
  border-top: 1px solid #eee;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  z-index: 0;
}

.social--widget.style--1 .nav>li:nth-child(1)>a,.social--widget.style--1 .nav>li:nth-child(2)>a,.social--widget.style--1 .nav>li:nth-child(3)>a {
  border-top-width: 0;
}

.social--widget.style--1 .nav>li>a:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-height: 50px;
  border-right: 1px solid #eee;
  z-index: -1;
}

.social--widget.style--1 .nav>li:nth-child(1)>a:before,.social--widget.style--1 .nav>li:nth-child(2)>a:before,.social--widget.style--1 .nav>li:nth-child(3)>a:before {
  top: auto;
  bottom: 0;
}

.social--widget.style--1 .nav>li:nth-child(3n)>a:before {
  border-right-width: 0;
}

.social--widget.style--1 .nav>li>a>span.icon {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border: 1px solid #777;
  border-radius: 50%;
  font-size: 24px;
  line-height: 48px;
  -webkit-transition: color .25s,background-color .25s ease-in-out;
  transition: color .25s,background-color .25s ease-in-out;
}

.social--widget.style--1 ul.nav>li>a:hover>span.icon {
  color: #fff;
  background-color: #777;
}

.social--widget.style--1 .nav>li>a>span.count {
  margin-top: 6px;
  color: #222;
  font-size: 18px;
  line-height: 28px;
}

.social--widget.style--1 .nav>li>a>span.title {
  margin-top: -1px;
  color: #999;
  font-size: 14px;
  line-height: 24px;
}

.social--widget.style--2 .row {
  margin-left: 0;
  margin-right: 0;
  padding: 10px;
}

.social--widget.style--2 .nav>li>a {
  position: relative;
  height: 50px;
  margin: 10px 0;
  padding: 0;
  color: #fff;
  background-color: #ddd;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  z-index: 0;
}

.social--widget.style--2 .nav>li>a>.icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 55px;
  background-color: rgba(255,255,255,.1);
  border-radius: 2px 0 0 2px;
  z-index: 1;
}

.social--widget.style--2 .nav>li>a>.icon:before {
  content: " ";
  position: absolute;
  top: 50%;
  right: 0;
  width: 0;
  height: 0;
  margin-top: -6px;
  border-style: solid;
  border-width: 6px 8px 6px 0;
  border-color: transparent #ddd transparent transparent;
}

.social--widget.style--2 .nav>li>a>.icon>i,.social--widget.style--2 .nav>li>a>.icon>span {
  margin-top: 12px;
  font-size: 18px;
  line-height: 28px;
  -webkit-transition-property: margin,font,line-height;
  transition-property: margin,font,line-height;
  -webkit-transition-duration: .25s;
  transition-duration: .25s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.social--widget.style--2 .nav>li>a:hover>.icon>i {
  margin-top: 5px;
  font-size: 14px;
  line-height: 24px;
}

.social--widget.style--2 .nav>li>a>.icon>span {
  display: block;
  margin-top: 6px;
  font-size: 0;
  line-height: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition-property: margin,font,line-height,opacity;
  transition-property: margin,font,line-height,opacity;
  -webkit-transition-duration: .35s;
  transition-duration: .35s;
}

.social--widget.style--2 .nav>li>a:hover>.icon>span {
  margin-top: -6px;
  font-size: 13px;
  line-height: 23px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.social--widget.style--2 .nav>li>a>.text>span {
  display: block;
  padding: 12px 0 10px 60px;
  font-family: "Alegreya Sans",sans-serif;
  font-size: 14px;
  line-height: 28px;
  -webkit-transform: rotateX(0) translateZ(0);
  transform: rotateX(0) translateZ(0);
  -webkit-transition: -webkit-transform .45s ease-in-out;
  transition: transform .45s ease-in-out;
  transition: transform .45s ease-in-out,-webkit-transform .45s ease-in-out;
}

.social--widget.style--2 .nav>li>a:hover>.text>span {
  -webkit-transform: rotateX(50deg) translateZ(50px);
  transform: rotateX(50deg) translateZ(50px);
}

.social--widget.style--2 .nav>li>a>.text>span+span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 9px;
  padding-bottom: 7px;
  font-size: 20px;
  line-height: 34px;
  font-weight: 500;
  -webkit-transform: rotateX(50deg) translateZ(-53px);
  transform: rotateX(50deg) translateZ(-53px);
  z-index: 0;
}

.social--widget.style--2 .nav>li>a:hover>.text>span+span {
  -webkit-transform: rotateX(0deg) translateZ(-53px);
  transform: rotateX(0deg) translateZ(-53px);
}

.social--widget.style--3 .nav {
  padding: 10px 0;
  text-align: center;
}

.social--widget.style--3 .nav>li {
  display: inline-block;
  margin: 6px 18px 3px;
}

.social--widget.style--3 .nav>li>a {
  padding: 0;
  color: #222;
  font-size: 24px;
  line-height: 30px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transition: opacity .25s;
  transition: opacity .25s;
}

.social--widget.style--3 .nav>li>a:hover {
  opacity: .6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
}

.social--widget.style--4 .nav {
  padding: 10px;
  text-align: center;
}

.social--widget.style--4 .nav>li,.social--widget.style--5 .nav>li {
  display: inline-block;
  margin: 10px;
}

.header--style-5 .header--menu-links>.megamenu>.dropdown-menu>li>.row>div,.social--widget.style--4 .nav>li>a {
  padding: 0;
}

.social--widget.style--4 .nav>li>a>i {
  min-width: 36px;
  padding: 3px 8px;
  font-size: 14px;
  line-height: 24px;
  color: #999;
  border: 1px solid #999;
  -webkit-transition: color .25s,background-color .25s ease-in-out;
  transition: color .25s,background-color .25s ease-in-out;
  text-align: center;
}

.social--widget.style--4 ul.nav>li>a:hover>i {
  color: #fff;
  background-color: #999;
}

.social--widget.style--5 .nav {
  padding: 10px;
}

.social--widget.style--5 .nav>li>a {
  position: relative;
  min-width: 50px;
  margin: 5px;
  padding: 0;
  color: #fff;
  background-color: #999;
  border-radius: 50%;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  z-index: 0;
}

.social--widget.style--5 .nav>li>a:hover {
  color: #999;
}

.social--widget.style--5 .nav>li>a:after,.social--widget.style--5 .nav>li>a:before {
  content: " ";
  position: absolute;
  border-radius: 50%;
  -webkit-transition: -webkit-transform .25s ease-in-out;
  transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out,-webkit-transform .25s ease-in-out;
  z-index: -1;
}

.social--widget.style--5 .nav>li>a:after {
  -webkit-transform: scale(0);
  transform: scale(0);
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background-color: #fff;
}

.social--widget.style--5 .nav>li>a:before {
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background-color: #999;
  opacity: .2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
}

.social--widget.style--5 .nav>li>a:before,.social--widget.style--5 .nav>li>a:hover:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.social--widget.style--5 .nav>li>a:hover:before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.social--widget.style--6 .nav {
  margin-left: 0;
  margin-right: 0;
  padding: 5px 10px 15px;
}

.social--widget.style--6 .nav>li {
  margin-top: 36px;
  margin-bottom: 5px;
}

.social--widget.style--6 .nav>li>a {
  min-height: 65px;
  padding: 0;
  color: #fff;
  background-color: #999;
  border: 1px solid #999;
  text-align: center;
  text-transform: uppercase;
}

.social--widget.style--6 .nav>li>a:hover {
  color: #fff;
  background-color: transparent;
}

.social--widget.style--6 .nav>li>a>.icon {
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  width: 50px;
  margin: 0 auto;
  background-color: #999;
  border: 3px solid #f8f8f8;
  border-radius: 50%;
  font-size: 18px;
  line-height: 44px;
  -webkit-transition: top .35s ease-in-out,opacity .35s ease-in-out;
  transition: top .35s ease-in-out,opacity .35s ease-in-out;
}

.social--widget.style--6 .nav>li>a:hover>.icon {
  top: -20px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.social--widget.style--6 .nav>li>a>.text,.social--widget.style--7 .nav>li>a>span {
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.social--widget.style--6 .nav>li>a>.text {
  margin-top: 13px;
  left: -15px;
  right: 10px;
  font-size: 14px;
  line-height: 24px;
  -webkit-transition: margin .35s ease-in-out,font .35s,left .35s ease-in-out;
  transition: margin .35s ease-in-out,font .35s,left .35s ease-in-out;
}

.social--widget.style--6 .nav>li>a:hover>.text {
  left: 10px;
  margin-top: 0;
  font-size: 16px;
  line-height: 26px;
}

.social--widget.style--6 .nav>li>a>.text>i {
  width: 20px;
  margin-left: 0;
  margin-right: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity .35s,margin .35s;
  transition: opacity .35s,margin .35s;
}

.social--widget.style--6 .nav>li>a:hover>.text>i {
  margin-left: 5px;
  margin-right: 5px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.social--widget.style--7 .nav {
  margin-left: 0;
  margin-right: 0;
  padding: 0 16px 20px;
}

.social--widget.style--7 .nav>li,.subscribe--widget .form-control+.btn,.subscribe--widget .form-control+.form-control {
  margin-top: 20px;
}

.social--widget.style--7 .nav>li>a {
  position: relative;
  min-height: 84px;
  padding: 17px;
  color: #ddd;
  background-color: #ddd;
  text-align: center;
  z-index: 0;
}

.social--widget.style--7 .nav>li>a:before,.social--widget.style--7 .nav>li>a>i {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  border-radius: 50%;
  -webkit-transition-duration: .55s;
  transition-duration: .55s;
}

.social--widget.style--7 .nav>li>a:before {
  content: " ";
  height: 60px;
  opacity: .2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  margin: -30px 0 0 -30px;
  width: 60px;
  -webkit-transition-property: left,background-color,opacity;
  transition-property: left,background-color,opacity;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  z-index: -1;
}

.social--widget.style--7 .nav>li>a:hover:before {
  left: 0;
  background-color: #f8f8f8;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.social--widget.style--7 .nav>li>a>i {
  display: block;
  width: 50px;
  margin: -25px 0 0 -25px;
  font-size: 18px;
  line-height: 50px;
  -webkit-transition: left .45s ease-in-out;
  transition: left .45s ease-in-out;
  -webkit-transition-property: color,left,background-color;
  transition-property: color,left,background-color;
  -webkit-transition-timing-function: ease,ease-in-out,ease-in-out;
  transition-timing-function: ease,ease-in-out,ease-in-out;
  z-index: 0;
}

.social--widget.style--7 .nav>li>a:hover>i {
  left: 0;
  color: #fff;
  background-color: #ddd;
}

.social--widget.style--7 .nav>li>a>span {
  left: 35px;
  right: 15px;
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity .55s ease 0s;
  transition: opacity .55s ease 0s;
  z-index: -1;
}

.social--widget.style--7 .nav>li>a:hover>span {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transition-delay: .25s;
  transition-delay: .25s;
}

.social--widget.style--1 .nav>li.facebook>a>span.icon,.social--widget.style--3 .nav>li>a>.fa-facebook,.social--widget.style--4 .nav>li>a>.fa-facebook,.social--widget.style--5 .nav>li.facebook>a:hover,.social--widget.style--7 .nav>li.facebook>a {
  color: #3b5998;
}

#stickySocial ul.nav>li>a:hover>.fa-facebook,#stickySocial ul.nav>li>a>.fa-facebook+span,.social--widget.style--1 .nav>li.facebook>a:hover>span.icon,.social--widget.style--2 .nav>li.facebook>a,.social--widget.style--4 .nav>li>a:hover>.fa-facebook,.social--widget.style--5 .nav>li.facebook>a,.social--widget.style--5 .nav>li.facebook>a:before,.social--widget.style--6 .nav>li.facebook>a,.social--widget.style--6 .nav>li.facebook>a>.icon,.social--widget.style--7 .nav>li.facebook>a,.social--widget.style--7 .nav>li.facebook>a:hover>i {
  background-color: #3b5998;
}

#stickySocial ul.nav>li>a:hover>.fa-facebook,#stickySocial ul.nav>li>a>.fa-facebook+span:before,.social--widget.style--1 .nav>li.facebook>a>span.icon,.social--widget.style--4 .nav>li>a>.fa-facebook,.social--widget.style--6 .nav>li.facebook>a {
  border-color: #3b5998;
}

.social--widget.style--2 .nav>li.facebook>a>.icon:before {
  border-right-color: #3b5998;
}

.social--widget.style--1 .nav>li.twitter>a>span.icon,.social--widget.style--3 .nav>li>a>.fa-twitter,.social--widget.style--4 .nav>li>a>.fa-twitter,.social--widget.style--5 .nav>li.twitter>a:hover,.social--widget.style--7 .nav>li.twitter>a {
  color: #00aced;
}

#stickySocial ul.nav>li>a:hover>.fa-twitter,#stickySocial ul.nav>li>a>.fa-twitter+span,.social--widget.style--1 .nav>li.twitter>a:hover>span.icon,.social--widget.style--2 .nav>li.twitter>a,.social--widget.style--4 .nav>li>a:hover>.fa-twitter,.social--widget.style--5 .nav>li.twitter>a,.social--widget.style--5 .nav>li.twitter>a:before,.social--widget.style--6 .nav>li.twitter>a,.social--widget.style--6 .nav>li.twitter>a>.icon,.social--widget.style--7 .nav>li.twitter>a,.social--widget.style--7 .nav>li.twitter>a:hover>i {
  background-color: #00aced;
}

#stickySocial ul.nav>li>a:hover>.fa-twitter,#stickySocial ul.nav>li>a>.fa-twitter+span:before,.social--widget.style--1 .nav>li.twitter>a>span.icon,.social--widget.style--4 .nav>li>a>.fa-twitter,.social--widget.style--6 .nav>li.twitter>a {
  border-color: #00aced;
}

.social--widget.style--2 .nav>li.twitter>a>.icon:before {
  border-right-color: #00aced;
}

.social--widget.style--1 .nav>li.google-plus>a>span.icon,.social--widget.style--3 .nav>li>a>.fa-google-plus,.social--widget.style--4 .nav>li>a>.fa-google-plus,.social--widget.style--5 .nav>li.google-plus>a:hover,.social--widget.style--7 .nav>li.google-plus>a {
  color: #d34836;
}

#stickySocial ul.nav>li>a:hover>.fa-google-plus,#stickySocial ul.nav>li>a>.fa-google-plus+span,.social--widget.style--1 .nav>li.google-plus>a:hover>span.icon,.social--widget.style--2 .nav>li.google-plus>a,.social--widget.style--4 .nav>li>a:hover>.fa-google-plus,.social--widget.style--5 .nav>li.google-plus>a,.social--widget.style--5 .nav>li.google-plus>a:before,.social--widget.style--6 .nav>li.google-plus>a,.social--widget.style--6 .nav>li.google-plus>a>.icon,.social--widget.style--7 .nav>li.google-plus>a,.social--widget.style--7 .nav>li.google-plus>a:hover>i {
  background-color: #d34836;
}

#stickySocial ul.nav>li>a:hover>.fa-google-plus,#stickySocial ul.nav>li>a>.fa-google-plus+span:before,.social--widget.style--1 .nav>li.google-plus>a>span.icon,.social--widget.style--4 .nav>li>a>.fa-google-plus,.social--widget.style--6 .nav>li.google-plus>a {
  border-color: #d34836;
}

.social--widget.style--2 .nav>li.google-plus>a>.icon:before {
  border-right-color: #d34836;
}

.social--widget.style--1 .nav>li.rss>a>span.icon,.social--widget.style--3 .nav>li>a>.fa-rss,.social--widget.style--4 .nav>li>a>.fa-rss,.social--widget.style--5 .nav>li.rss>a:hover,.social--widget.style--7 .nav>li.rss>a {
  color: #f60;
}

#stickySocial ul.nav>li>a:hover>.fa-rss,#stickySocial ul.nav>li>a>.fa-rss+span,.social--widget.style--1 .nav>li.rss>a:hover>span.icon,.social--widget.style--2 .nav>li.rss>a,.social--widget.style--4 .nav>li>a:hover>.fa-rss,.social--widget.style--5 .nav>li.rss>a,.social--widget.style--5 .nav>li.rss>a:before,.social--widget.style--6 .nav>li.rss>a,.social--widget.style--6 .nav>li.rss>a>.icon,.social--widget.style--7 .nav>li.rss>a,.social--widget.style--7 .nav>li.rss>a:hover>i {
  background-color: #f60;
}

#stickySocial ul.nav>li>a:hover>.fa-rss,#stickySocial ul.nav>li>a>.fa-rss+span:before,.social--widget.style--1 .nav>li.rss>a>span.icon,.social--widget.style--4 .nav>li>a>.fa-rss,.social--widget.style--6 .nav>li.rss>a {
  border-color: #f60;
}

.social--widget.style--2 .nav>li.rss>a>.icon:before {
  border-right-color: #f60;
}

.social--widget.style--1 .nav>li.vimeo>a>span.icon,.social--widget.style--3 .nav>li>a>.fa-vimeo,.social--widget.style--4 .nav>li>a>.fa-vimeo,.social--widget.style--5 .nav>li.vimeo>a:hover,.social--widget.style--7 .nav>li.vimeo>a {
  color: #4ebbff;
}

#stickySocial ul.nav>li>a:hover>.fa-vimeo,#stickySocial ul.nav>li>a>.fa-vimeo+span,.social--widget.style--1 .nav>li.vimeo>a:hover>span.icon,.social--widget.style--2 .nav>li.vimeo>a,.social--widget.style--4 .nav>li>a:hover>.fa-vimeo,.social--widget.style--5 .nav>li.vimeo>a,.social--widget.style--5 .nav>li.vimeo>a:before,.social--widget.style--6 .nav>li.vimeo>a,.social--widget.style--6 .nav>li.vimeo>a>.icon,.social--widget.style--7 .nav>li.vimeo>a,.social--widget.style--7 .nav>li.vimeo>a:hover>i {
  background-color: #4ebbff;
}

#stickySocial ul.nav>li>a:hover>.fa-vimeo,#stickySocial ul.nav>li>a>.fa-vimeo+span:before,.social--widget.style--1 .nav>li.vimeo>a>span.icon,.social--widget.style--4 .nav>li>a>.fa-vimeo,.social--widget.style--6 .nav>li.vimeo>a {
  border-color: #4ebbff;
}

.social--widget.style--2 .nav>li.vimeo>a>.icon:before {
  border-right-color: #4ebbff;
}

.social--widget.style--1 .nav>li.youtube>a>span.icon,.social--widget.style--3 .nav>li>a>.fa-youtube-play,.social--widget.style--4 .nav>li>a>.fa-youtube-play,.social--widget.style--5 .nav>li.youtube>a:hover,.social--widget.style--7 .nav>li.youtube>a {
  color: #b00;
}

#stickySocial ul.nav>li>a:hover>.fa-youtube-play,#stickySocial ul.nav>li>a>.fa-youtube-play+span,.social--widget.style--1 .nav>li.youtube>a:hover>span.icon,.social--widget.style--2 .nav>li.youtube>a,.social--widget.style--4 .nav>li>a:hover>.fa-youtube-play,.social--widget.style--5 .nav>li.youtube>a,.social--widget.style--5 .nav>li.youtube>a:before,.social--widget.style--6 .nav>li.youtube>a,.social--widget.style--6 .nav>li.youtube>a>.icon,.social--widget.style--7 .nav>li.youtube>a,.social--widget.style--7 .nav>li.youtube>a:hover>i {
  background-color: #b00;
}

#stickySocial ul.nav>li>a:hover>.fa-youtube-play,#stickySocial ul.nav>li>a>.fa-youtube-play+span:before,.social--widget.style--1 .nav>li.youtube>a>span.icon,.social--widget.style--4 .nav>li>a>.fa-youtube-play,.social--widget.style--6 .nav>li.youtube>a {
  border-color: #b00;
}

.social--widget.style--2 .nav>li.youtube>a>.icon:before {
  border-right-color: #b00;
}

.social--widget.style--1 .nav>li.dribbble>a>span.icon,.social--widget.style--3 .nav>li>a>.fa-dribbble,.social--widget.style--4 .nav>li>a>.fa-dribbble,.social--widget.style--5 .nav>li.dribbble>a:hover,.social--widget.style--7 .nav>li.dribbble>a {
  color: #ea4c89;
}

#stickySocial ul.nav>li>a:hover>.fa-dribbble,#stickySocial ul.nav>li>a>.fa-dribbble+span,.social--widget.style--1 .nav>li.dribbble>a:hover>span.icon,.social--widget.style--2 .nav>li.dribbble>a,.social--widget.style--4 .nav>li>a:hover>.fa-dribbble,.social--widget.style--5 .nav>li.dribbble>a,.social--widget.style--5 .nav>li.dribbble>a:before,.social--widget.style--6 .nav>li.dribbble>a,.social--widget.style--6 .nav>li.dribbble>a>.icon,.social--widget.style--7 .nav>li.dribbble>a,.social--widget.style--7 .nav>li.dribbble>a:hover>i {
  background-color: #ea4c89;
}

#stickySocial ul.nav>li>a:hover>.fa-dribbble,#stickySocial ul.nav>li>a>.fa-dribbble+span:before,.social--widget.style--1 .nav>li.dribbble>a>span.icon,.social--widget.style--4 .nav>li>a>.fa-dribbble,.social--widget.style--6 .nav>li.dribbble>a {
  border-color: #ea4c89;
}

.social--widget.style--2 .nav>li.dribbble>a>.icon:before {
  border-right-color: #ea4c89;
}

.social--widget.style--1 .nav>li.linkedin>a>span.icon,.social--widget.style--3 .nav>li>a>.fa-linkedin,.social--widget.style--4 .nav>li>a>.fa-linkedin,.social--widget.style--5 .nav>li.linkedin>a:hover,.social--widget.style--7 .nav>li.linkedin>a {
  color: #0077b5;
}

#stickySocial ul.nav>li>a:hover>.fa-linkedin,#stickySocial ul.nav>li>a>.fa-linkedin+span,.social--widget.style--1 .nav>li.linkedin>a:hover>span.icon,.social--widget.style--2 .nav>li.linkedin>a,.social--widget.style--4 .nav>li>a:hover>.fa-linkedin,.social--widget.style--5 .nav>li.linkedin>a,.social--widget.style--5 .nav>li.linkedin>a:before,.social--widget.style--6 .nav>li.linkedin>a,.social--widget.style--6 .nav>li.linkedin>a>.icon,.social--widget.style--7 .nav>li.linkedin>a,.social--widget.style--7 .nav>li.linkedin>a:hover>i {
  background-color: #0077b5;
}

#stickySocial ul.nav>li>a:hover>.fa-linkedin,#stickySocial ul.nav>li>a>.fa-linkedin+span:before,.social--widget.style--1 .nav>li.linkedin>a>span.icon,.social--widget.style--4 .nav>li>a>.fa-linkedin,.social--widget.style--6 .nav>li.linkedin>a {
  border-color: #0077b5;
}

.social--widget.style--2 .nav>li.linkedin>a>.icon:before {
  border-right-color: #0077b5;
}

.social--widget.style--1 .nav>li.pinterest>a>span.icon,.social--widget.style--3 .nav>li>a>.fa-pinterest-p,.social--widget.style--4 .nav>li>a>.fa-pinterest-p,.social--widget.style--5 .nav>li.pinterest>a:hover,.social--widget.style--7 .nav>li.pinterest>a {
  color: #bd081c;
}

#stickySocial ul.nav>li>a:hover>.fa-pinterest-p,#stickySocial ul.nav>li>a>.fa-pinterest-p+span,.social--widget.style--1 .nav>li.pinterest>a:hover>span.icon,.social--widget.style--2 .nav>li.pinterest>a,.social--widget.style--4 .nav>li>a:hover>.fa-pinterest-p,.social--widget.style--5 .nav>li.pinterest>a,.social--widget.style--5 .nav>li.pinterest>a:before,.social--widget.style--6 .nav>li.pinterest>a,.social--widget.style--6 .nav>li.pinterest>a>.icon,.social--widget.style--7 .nav>li.pinterest>a,.social--widget.style--7 .nav>li.pinterest>a:hover>i {
  background-color: #bd081c;
}

#stickySocial ul.nav>li>a:hover>.fa-pinterest-p,#stickySocial ul.nav>li>a>.fa-pinterest-p+span:before,.social--widget.style--1 .nav>li.pinterest>a>span.icon,.social--widget.style--4 .nav>li>a>.fa-pinterest-p,.social--widget.style--6 .nav>li.pinterest>a {
  border-color: #bd081c;
}

.social--widget.style--2 .nav>li.pinterest>a>.icon:before {
  border-right-color: #bd081c;
}

.subscribe--widget {
  padding: 30px 20px;
}

.subscribe--widget .content {
  margin-top: -7px;
  margin-bottom: 26px;
}

.subscribe--widget .content img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.subscribe--widget .form-control {
  height: 40px;
  padding-top: 7px;
  padding-bottom: 7px;
  background-color: #eee;
  border-width: 0;
}

.subscribe--widget .btn {
  min-width: 50px;
  padding: 6px 16px;
}

.subscribe--widget .status {
  display: none;
  padding-top: 13px;
}

.list--widget,.subscribe--widget {
  background-color: #f8f8f8;
}

.list--widget-nav {
  padding: 20px 20px 15px;
  color: #999;
}

.list--widget-nav>.nav>li {
  border: 1px solid #eee;
  vertical-align: middle;
  -webkit-transition: border-color .25s,background-color .25s ease-in-out;
  transition: border-color .25s,background-color .25s ease-in-out;
}

.list--widget-nav>.nav>li+li {
  border-left-width: 0;
}

.list--widget-nav>.nav>li.active {
  background-color: #da0000;
  border-color: #da0000;
}

.list--widget-nav .nav>li>a {
  padding: 5px;
  line-height: 18px;
}

.list--widget-nav ul.nav>li.active>a {
  color: #fff;
}

.list--widget .post--items>.nav {
  padding-top: 20px;
  padding-bottom: 20px;
}

.list--widget-nav+.post--items>.nav {
  border-top: 1px solid #eee;
}

.header--style-5 .header--menu-links>.megamenu>.dropdown-menu>.dropdown>.dropdown-menu>li>a,.header--style-5 .header--menu-links>.megamenu>.dropdown-menu>.dropdown>a,.list--widget .post--items>.nav>li {
  padding-left: 20px;
  padding-right: 20px;
}

.list--widget .preloader {
  opacity: .7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
}

.list--widget-2 .post--item.post--layout-3 .post--img .thumb,.list--widget-2 .post--item.post--layout-4 .post--img .thumb {
  max-width: 70px;
  border: 2px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
}

.list--widget-2 .post--info .title .h4:before {
  content: "\f10d";
  margin-left: 1px;
  margin-right: 9px;
  color: #999;
  font-family: "FontAwesome";
}

.list--widget-2 .post--info .meta>li:first-child {
  color: #555;
}

.poll--widget {
  position: relative;
  background-color: #f8f8f8;
}

.poll--widget .title {
  padding: 12px 20px 13px;
  border-bottom: 1px solid #eee;
}

.poll--widget .title .h4 {
  margin: 0;
  color: #222;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.poll--widget .options {
  padding: 14px 20px 20px;
}

.header--style-5 .header--menu-links>.megamenu>.dropdown-menu>li>.row,.poll--widget .checkbox,.poll--widget .radio {
  margin: 0;
}

.poll--widget .checkbox+.checkbox,.poll--widget .radio+.radio {
  margin-top: 18px;
}

.poll--widget .checkbox:after,.poll--widget .checkbox:before,.poll--widget .radio:after,.poll--widget .radio:before {
  content: " ";
  display: table;
}

.poll--widget .checkbox:after,.poll--widget .radio:after {
  clear: both;
}

.poll--widget .checkbox label,.poll--widget .checkbox p,.poll--widget .radio label,.poll--widget .radio p {
  float: left;
  width: 50%;
}

.poll--widget .checkbox label,.poll--widget .radio label {
  display: block;
  position: relative;
  padding-left: 22px;
  padding-right: 5px;
}

.poll--widget .checkbox label span:before,.poll--widget .radio label span:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -6px;
  width: 12px;
  height: 12px;
  padding: 0 0 1px 1px;
  color: #222;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-family: "FontAwesome";
  font-size: 9px;
  line-height: 9px;
}

.poll--widget .radio label span:before {
  border-radius: 50%;
  border-width: 4px;
  border-color: #eee;
}

.poll--widget .checkbox p,.poll--widget .radio p {
  display: block;
  position: relative;
  text-align: right;
}

.poll--widget .checkbox p span,.poll--widget .checkbox p:before,.poll--widget .radio p span,.poll--widget .radio p:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  right: 36px;
  height: 12px;
  margin-top: -6px;
}

.poll--widget .checkbox p:before,.poll--widget .radio p:before {
  border: 1px solid #ccc;
}

.poll--widget .checkbox p span,.poll--widget .radio p span {
  width: 0%;
  background-color: #da0000;
  -webkit-transition: width .25s ease-in-out;
  transition: width .25s ease-in-out;
}

.poll--widget .options .btn {
  margin-top: 34px;
}


.about--widget .action {
  margin-top: 16px;
}

.about--widget>.nav,.tags--widget .nav {
  font-size: 0;
  line-height: 0;
}

.about--widget .action+.nav {
  margin-top: 8px;
  padding-top: 11px;
  border-top: 1px solid #333;
}

.about--widget>.nav>li+li {
  margin-top: 10px;
}

.about--widget>.nav>li>.fa {
  min-width: 14px;
  margin-right: 8px;
  font-size: 14px;
  line-height: 24px;
}

.bg--color-2 .about--widget>.nav>li>.fa {
  color: #fff;
}

.about--widget>.nav>li>a,.about--widget>.nav>li>span {
  display: inline-block;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
}

.links--widget .nav>li+li {
  margin-top: 5px;
}

.links--widget .nav>li>a {
  padding: 0 0 1px 12px;
  border-bottom: 1px dotted #333;
}

.links--widget .nav>li>a:before {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  font-family: "FontAwesome";
}

.nav--widget {
  color: #999;
  background-color: #f8f8f8;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.nav--widget .nav>li+li {
  border-top: 1px solid #eee;
}

.nav--widget .nav>li>a {
  padding: 12px 20px 13px;
  overflow: hidden;
  z-index: 0;
}

.nav--widget .nav>li>a span {
  display: block;
  float: left;
  padding-right: 6px;
  background-color: #f8f8f8;
}

.nav--widget .nav>li>a span+span {
  float: right;
  padding-right: 0;
  padding-left: 8px;
}

.nav--widget .nav>li>a span+span:before {
  content: " ";
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border-bottom: 2px dotted;
  z-index: -1;
}

.tags--widget {
  padding: 20px 20px 10px;
  background-color: #f8f8f8;
}

.tags--widget .nav {
  color: #999;
  text-transform: uppercase;
}

.tags--widget .nav>li {
  display: inline-block;
}

.tags--widget .nav>li>a {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  border: 1px solid;
  font-size: 12px;
  line-height: 22px;
}

.tags--widget .nav>li>a:hover {
  color: #fff;
  background-color: #da0000;
  border-color: #da0000;
}

.header--menu-links>.megamenu>.dropdown-menu:after,.header--menu-links>.megamenu>.dropdown-menu:before,.news--ticker:after,.news--ticker:before,.tags--widget .nav>li>a:after,.tags--widget .nav>li>a:before {
  content: " ";
  display: table;
}

.header--menu-links>.megamenu>.dropdown-menu:after,.news--ticker:after,.tags--widget .nav>li>a:after {
  clear: both;
}

.tags--widget .nav>li>a>span {
  float: right;
  display: block;
  position: relative;
  margin: -1px -11px -1px 7px;
  padding: 1px 8px;
  color: #fff;
  background-color: #da0000;
  z-index: 0;
}

.tags--widget .nav>li>a>span:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .08;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=8)";
  z-index: -1;
}

.tags--widget.style--2 .nav>li>a {
  padding: 1px 11px;
  color: #fff;
  border-width: 0;
}

.tags--widget.style--2 .nav>li>a:focus,.tags--widget.style--2 .nav>li>a:hover {
  background-color: #222;
}

.tags--widget.style--4 .nav {
  margin-left: -10px;
  margin-right: -10px;
}

.tags--widget.style--4 ul.nav>li>a {
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
  background-color: transparent;
  border-width: 0;
  font-size: 14px;
  line-height: 18px;
}

.tags--widget.style--4 .nav>li>a:hover {
  color: #da0000;
}

.widget>.search--widget {
  padding: 30px 20px;
  box-shadow: 0 1px 10px rgba(0,0,0,.05);
}

.search--widget .form-control {
  border-color: #eee;
}

.search--widget .input-group {
  padding: 2px;
  z-index: 1;
}

.search--widget .input-group .form-control {
  border-width: 0;
}

.search--widget .input-group-btn {
  position: static;
  padding-right: 1px;
  color: #999;
  font-size: 14px;
  line-height: 24px;
}

.search--widget .input-group-btn:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #eee;
  -webkit-transition: border-color .25s;
  transition: border-color .25s;
  z-index: -1;
}

.search--widget .input-group-btn button {
  padding: 4px 27px;
}

.search--widget .form-control+.btn {
  margin-top: 20px;
  padding: 5px 20px;
}

.search--widget button.dropdown-toggle {
  margin: -1px 0 -1px -1px;
  padding: 7px 12px;
  border-width: 0;
  background-color: #eee;
}

.search--widget .input-group-btn:first-child>.btn {
  margin-left: -1px;
  margin-right: 0;
}

.megamenu--posts>ul>li>.img a.cat:hover:before,.search--widget button.dropdown-toggle:hover {
  background-color: #da0000;
}

.search--widget .dropdown-menu {
  padding: 0 0 2px;
}

.search--widget li label {
  display: block;
  padding: 3px 13px;
  margin-bottom: 0;
  font-weight: 600;
  cursor: pointer;
}

.search--widget li label span {
  display: block;
  -webkit-transition: color .25s;
  transition: color .25s;
}

.search--widget li label input:checked+span,.search--widget li label span:hover {
  color: #da0000;
}

.cart--widget {
  padding: 20px 0;
  color: #999;
  background-color: #f8f8f8;
}

.cart--widget li {
  padding-left: 20px;
  padding-right: 35px;
}

.cart--widget li+li {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.cart--widget .img {
  float: left;
  max-width: 80px;
  margin-right: 10px;
}

.cart--widget .img a,.megamenu--posts>ul>li>.img a {
  display: block;
  position: relative;
  z-index: 0;
}

.cart--widget .img a:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-linear-gradient(top,#fff 0%,#000 100%);
  background: linear-gradient(to bottom,#fff 0%,#000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
  opacity: .35;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
  -webkit-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
  z-index: 1;
}

.cart--widget .img a:hover:before {
  opacity: .9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
}

.cart--widget .info .h4 {
  margin: -8px 0 0;
  color: #222;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.cart--widget .info .h4 a {
  display: inline;
}

.cart--widget .info {
  overflow: hidden;
}

.cart--widget .info p {
  margin-top: 3px;
}

.cart--widget .remove {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -12px;
  padding: 0;
}

.cart--widget li+li .remove {
  margin-top: -2px;
}

.cart--widget .footer {
  margin-top: 20px;
  padding: 20px 20px 0;
}

.cart--widget .footer .amount {
  margin-top: -3px;
}

.cart--widget .footer .amount p {
  float: left;
  margin-bottom: 18px;
  color: #222;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}

.cart--widget .footer .amount p+p {
  float: right;
  color: #da0000;
  font-size: 26px;
  font-weight: 700;
}

.cart--widget .footer,.cart--widget .footer .actions,.megamenu--filter .nav>li+li {
  border-top: 1px solid #eee;
}

.cart--widget .footer .actions .btn {
  margin-top: 20px;
}

.cart--widget .footer .actions .btn-primary {
  float: right;
}

.twitter--widget {
  border: 1px solid #f8f8f8;
  font-size: 0;
  line-height: 0;
}

.flicker--widget {
  padding: 15px;
}

div#flickr_badge_wrapper {
  width: 100%;
}

.flicker--widget img {
  width: 72px;
  height: 72px;
}

.flicker--widget a[href="https://www.flickr.com/"] {
  display: block;
  padding: 0 5px;
  text-align: center;
}

.flicker--widget,.profile--widget {
  background-color: #f8f8f8;
}

.profile--widget .img img {
  border-radius: 50%;
}

.profile--widget .social {
  margin-bottom: 0;
}

.header--topbar {
  /*padding: 13px 0 6px;*/
  color: #751704;  
  font-size: 0;
  line-height: 0;
}

.header--topbar-action,.header--topbar.bg--color-1 {
  color: #fff;
}

.header--style-6 .header--topbar {
  border-bottom: 1px solid #e9e9e9;
}

.header--topbar .nav {
  display: inline-block;
  margin: 0 -10px 0 15px;
  padding-left: 5px;
  border-left: 1px solid #555;
  font-size: 17px;
  line-height: 24px;
}

.header--topbar.bg--color-1 .nav {
  border-color: rgba(255,255,255,.75);
}

.header--style-6 .header--topbar .nav {
  border-color: #ddd;
}

.header--topbar .nav:first-child {
  margin-left: -10px;
  padding-left: 0;
  border-left-width: 0;
}

.header--topbar .nav>li {
  float: left;
  padding: 10px 10px;
  /*border-right:#828282 1px solid;*/
}

.header--topbar .nav>li>a {
  padding: 0;
  -webkit-transition: opacity .25s;
  transition: opacity .25s;
}

.header--topbar ul.nav>li.open>a,.header--topbar ul.nav>li>a:hover {
  color: inherit;
  opacity: .5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

.header--topbar .dropdown-menu {
  min-width: 105px;
  margin-top: 13px;
  margin-left: -15px;
  left: 0;
}

.checkout--billing-info .panel-group .panel-title label.collapsed,.header--topbar.bg--color-1 .dropdown-menu {
  color: #999;
}

.header--topbar .dropdown-menu>li>a {
  padding: 5px 15px;
}

.header--style-6 .header--topbar-action {
  color: inherit;
}

.header--mainbar {
  padding: 30px 0;
}

.header--style-7 .header--mainbar,.header--style-8 .header--mainbar {
  border-bottom: 1px solid #eee;
}

.header--logo {
  font-size: 0;
  line-height: 0;
}

.header--logo .h1 {
  display: inline-block;
  margin: 0;
  color: #1d1d1d;
  font-weight: 700;
}

.header--logo a {
  display: table-cell;
  height: 90px;
  vertical-align: middle;
}

.header--style-2 .header--logo a,.header--style-8 .header--logo a {
  height: auto;
}

.header--navbar {
  margin-bottom: 0;
  border-style: solid;
  border-width: 2px 0 0;
  border-radius: 0;
  -webkit-transition: box-shadow .25s ease-in-out;
  transition: box-shadow .25s ease-in-out;
}

.header--style-6 .header--navbar {
  border-top-width: 0;
  box-shadow: 0 4px 7px rgba(0,0,0,.1);
}

.header--style-7 .header--navbar,.header--style-8 .header--navbar {
  box-shadow: 0 2px 5px rgba(0,0,0,.1);
}

.header--navbar,.header--style-7 .is-sticky .header--navbar,.header--style-8 .is-sticky .header--navbar {
  box-shadow: 0 3px 7px rgba(0,0,0,.2);
}

.header--navbar .container {
  position: relative;
}

.header--navbar-inner {
  padding: 0 15px;
}

.header--style-5 .header--menu-links>li,.header--style-5 .navbar-header {
  float: none;
}

.header--navbar .navbar-toggle {
  margin: 12px 0 13px;
  background-color: #222;
  border-color: #222;
  border-radius: 0;
  -webkit-transition: border-color .55s,background-color .55s ease-in-out;
  transition: border-color .55s,background-color .55s ease-in-out;
}

.header--style-5 .navbar-toggle {
  display: block;
  position: absolute;
  top: 0;
  left: 15px;
  margin-top: 28px;
  margin-bottom: 28px;
}

.header--navbar .navbar-toggle.collapsed {
  background-color: transparent;
  border-color: #222;
}

.header--style-6 .navbar-toggle.collapsed {
  border-color: inherit;
}

.header--navbar.bg--color-1 .navbar-toggle.collapsed {
  border-color: #fff;
}

.header--navbar .navbar-toggle .icon-bar {
  background-color: #fff;
  border-radius: 0;
}

.header--navbar .navbar-toggle.collapsed .icon-bar,.posts--filter-bar.style--3 .nav>li>a:hover>i {
  background-color: #222;
}

.bg--color-1 .page--title.lined .h2:before,.header--navbar.bg--color-1 .navbar-toggle.collapsed .icon-bar,.header--style-6 .navbar-toggle.collapsed .icon-bar {
  background-color: #fff;
}

.header--menu-links>.megamenu>.dropdown-menu>.dropdown>.dropdown-menu>li>a,.header--navbar .navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}

.header--style-5 .navbar-collapse {
  display: block!important;
  position: fixed;
  top: 0;
  left: -300px;
  bottom: 0;
  width: 250px;
  height: auto!important;
  margin: 0;
  color: #777;
  background-color: #fff;
  border-width: 0;
  box-shadow: 0 3px 7px rgba(0,0,0,.2);
  overflow: visible!important;
  -webkit-transition: left .55s ease-in-out;
  transition: left .55s ease-in-out;
  z-index: 999;
}

.header--style-5 .navbar-collapse:before {
  content: "\f00d";
  position: absolute;
  top: 0;
  right: -40px;
  width: 40px;
  color: #fff;
  background-color: #da0000;
  box-shadow: 1px 3px 5px rgba(0,0,0,.2);
  font-family: "FontAwesome";
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  z-index: 9999;
}

.header--style-5 .navbar-collapse.in {
  left: 0;
}

.header--menu-links {
  font-size: 16px;
  line-height: 26px;
}

.header--menu-links>li>a {
  padding: 18px 15px 19px;
  border-bottom: 2px solid transparent;
}

.header--style-4 .header--menu-links>li>a {
  padding-top: 34px;
  padding-bottom: 34px;
}

.header--menu-links>li.active>a,.header--menu-links>li.open>a,.header--menu-links>li.open>a:focus,.header--menu-links>li.open>a:hover,.header--menu-links>li>a:hover {
  border-bottom-color: #da0000;
}

.bg--color-1 .header--menu-links>li.active>a,.bg--color-1 .header--menu-links>li.open>a,.bg--color-1 .header--menu-links>li.open>a:focus,.bg--color-1 .header--menu-links>li.open>a:hover,.bg--color-1 .header--menu-links>li>a:hover {
  border-bottom-color: inherit;
}

ul.header--menu-links.nav>li.active>a,ul.header--menu-links.nav>li.active>a:hover,ul.header--menu-links.nav>li.open>a,ul.header--menu-links.nav>li.open>a:focus,ul.header--menu-links.nav>li.open>a:hover,ul.header--menu-links.nav>li>a:hover {
  color: inherit;
}

.header--menu-links>.dropdown>.dropdown-menu {
  color: #555;
  font-size: 16px;
  line-height: 26px;
}

.header--style-5 .header--menu-links {
  float: none;
  height: 100%;
  margin: 0;
  padding-bottom: 5px;
  overflow: auto;
}

.header--style-5 .header--menu-links>li>a {
  padding-top: 12px;
  padding-bottom: 10px;
}

.header--style-5 .header--menu-links>.dropdown>.dropdown-menu {
  float: none;
  position: relative!important;
  border-bottom-width: 0;
  box-shadow: none;
}

.header--style-5 .header--menu-links>.megamenu>.dropdown-menu {
  left: 0;
  right: 0;
  padding: 0;
  border-bottom-width: 1px;
}

.header--style-5 .header--menu-links>.megamenu.filter>.dropdown-menu,.header--style-5 .header--menu-links>.megamenu.posts>.dropdown-menu {
  border-bottom-width: 0;
}

.header--style-5 .header--menu-links>.megamenu.filter>.dropdown-menu,.header--style-5 .header--menu-links>.megamenu.filter>.dropdown-toggle>i,.header--style-5 .header--menu-links>.megamenu.posts>.dropdown-menu,.header--style-5 .header--menu-links>.megamenu.posts>.dropdown-toggle>i {
  display: none;
}

.header--style-5 .header--menu-links>.megamenu>.dropdown-menu>.dropdown {
  float: none;
  margin: 0;
  width: 100%;
}

.header--style-5 .dropdown-menu>.dropdown>.dropdown-menu {
  float: none;
  position: relative;
  top: 1px;
  left: 0;
  width: 100%;
  border-bottom-width: 1px;
  box-shadow: none;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.header--style-5 .dropdown-menu>.dropdown>.dropdown-menu>li>a {
  padding-left: 30px;
  padding-right: 30px;
}

.header--menu-links>.megamenu {
  position: static;
}

.header--menu-links>.megamenu>.dropdown-menu {
  position: absolute!important;
  left: 15px;
  right: 15px;
  padding: 3px 10px 5px;
  font-size: 0;
  line-height: 0;
}

.header--menu-links>.megamenu>.dropdown-menu>.dropdown {
  float: left;
  margin: 0 10px;
}

.header--menu-links>.megamenu>.dropdown-menu>.dropdown+li {
  border-top-width: 0;
}

.header--menu-links>.megamenu>.dropdown-menu>.dropdown>a {
  padding-left: 0;
  padding-right: 0;
  color: #222;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.header--menu-links>.megamenu>.dropdown-menu>.dropdown>.dropdown-menu {
  display: block;
  float: none;
  position: relative;
  left: 0;
  right: 0;
  padding: 0;
  border-bottom-width: 0;
  box-shadow: none;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.megamenu--filter,.megamenu--pagination a {
  margin: 2px -10px 0;
  font-size: 16px;
  line-height: 26px;
}

.megamenu--filter .nav>li>a {
  padding: 7px 20px 8px;
}

.megamenu--filter .nav>li>a>.fa {
  margin-left: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity .25s,margin .25s ease-in-out;
  transition: opacity .25s,margin .25s ease-in-out;
}

.megamenu--filter .nav>li.active>a>.fa,.megamenu--filter .nav>li>a:hover>.fa {
  margin-left: 5px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.megamenu--posts {
  margin: 17px 3px 20px;
}

.megamenu--posts>ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.megamenu--posts>ul>li {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.megamenu--posts,.megamenu--posts>ul>li>.img {
  position: relative;
  z-index: 0;
}

.megamenu--posts>ul>li>.img a.thumb:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  background: -webkit-linear-gradient(top,#fff 0%,#000 100%);
  background: linear-gradient(to bottom,#fff 0%,#000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
  opacity: .35;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
  z-index: 1;
}

.megamenu--posts>ul>li>.img a.cat {
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 15px;
  padding: 0 10px;
  color: #fff;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  z-index: 0;
}

.megamenu--posts>ul>li>.img a.cat:before,.megamenu--posts>ul>li>.img a.icon:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: .8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  -webkit-transition: background-color .25s ease-in-out;
  transition: background-color .25s ease-in-out;
  z-index: -1;
}

.megamenu--posts>ul>li>.img a.icon {
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 36px;
  padding-bottom: 2px;
  color: #fff;
  font-size: 16px;
  line-height: 34px;
  text-align: center;
  z-index: 0;
}

.megamenu--posts>ul>li>.img a.icon:before {
  background-color: #da0000;
}

.megamenu--posts>ul>li>.img a.icon:hover:before {
  background-color: #000;
}

.megamenu--posts>ul>li>.title {
  display: block;
  margin: -5px 0 -7px;
  color: #222;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.megamenu--posts>ul>li:hover>.title {
  color: #da0000;
}

.megamenu--posts>ul>li>.img+.title {
  margin-top: 10px;
}

.megamenu--posts .preloader {
  display: none;
  bottom: -5px;
  z-index: 1;
}

.megamenu--posts .preloader:before,.post--item.post--layout-1 .post--img:hover .thumb:before {
  opacity: .9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
}

.megamenu--pagination {
  margin-top: 44px;
  margin-bottom: 8px;
  color: #999;
  text-align: center;
}

.megamenu--pagination a {
  margin: 0 5px;
}

.megamenu--pagination .tooltip-inner {
  padding: 5px 7px 4px;
  background-color: #222;
  border-radius: 0;
}

.megamenu--pagination .tooltip-arrow {
  border-top-color: #222;
}

.header--search-form {
  position: relative;
  min-width: 32px;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-align: right;
  z-index: 1;
}

.header--style-4 .header--search-form {
  margin-top: 16px;
  margin-bottom: 16px;
}

.header--style-5 .header--search-form {
  position: absolute;
  top: 0;
  right: 15px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.header--search-form .form-control {
  display: block;
  position: absolute;
  top: 11px;
  right: 0;
  width: 0;
  background-color: #f0f0f0;
  border: 0;
  border-radius: 20px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: hidden;
  -webkit-transition-property: width,opacity,visibility;
  transition-property: width,opacity,visibility;
  -webkit-transition-duration: .55s;
  transition-duration: .55s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  z-index: 0;
}

.header--search-form.active .form-control {
  width: 220px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
}

.header--search-form .btn {
  position: relative;
  padding: 15px 0;
  background-color: transparent;
  font-size: 16px;
  line-height: 26px;
  -webkit-transition: color .35s,padding .35s ease-in-out;
  transition: color .35s,padding .35s ease-in-out;
  outline: 0;
  z-index: 1;
}

.header--search-form .btn:focus,.header--search-form .btn:hover {
  color: inherit;
}

.header--search-form.active .btn {
  padding-right: 15px;
  color: #999;
  box-shadow: none;
  outline: 0;
}

.header--search-form.active .btn:focus,.header--search-form.active .btn:hover {
  color: #999;
}

.banner--content .title .h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.banner--content .sub-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

.banner--section .buttons {
  font-size: 0;
  line-height: 0;
}

.banner--section .buttons .btn {
  min-width: 150px;
  margin: 15px 10px 0;
}

.feature--item .icon {
  width: 50px;
  margin-right: 20px;
  color: #fff;
  background-color: #da0000;
  box-shadow: 0 2px 10px rgba(0,0,0,.08);
  font-size: 24px;
  line-height: 50px;
  text-align: center;
}

.feature--item .info {
  overflow: hidden;
}

.feature--item .title .h3 {
  margin: 11px 0 0;
  color: #222;
  font-weight: 600;
}

.feature--item .content {
  margin-top: 7px;
  font-size: 16px;
  line-height: 26px;
}

.feature--content .title {
  margin-bottom: 17px;
  color: #222;
}

.feature--content .title .h3 {
  margin: 0;
}

.posts--filter-bar .nav {
  margin-top: 5px;
  /*color: #999;*/
  font-size: 0;
  line-height: 0;
  text-align: center;
}

.posts--filter-bar .nav>li {
  display: inline-block;
  margin-top: 15px;
}

.posts--filter-bar .nav>li>a {
  position: relative;
  padding: 0 20px 7px;
  border-bottom: 1px solid #c4c4c4;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  z-index: 0;
}

.posts--filter-bar .nav>li>a>i {
  display: block;
  margin-bottom: 4px;
  -webkit-transition: color .25s,border-color .25s,background-color .25s ease-in-out;
  transition: color .25s,border-color .25s,background-color .25s ease-in-out;
}

.posts--filter-bar .nav>li>a:before {
  content: " ";
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  bottom: 0;
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: #c4c4c4;
}

.posts--filter-bar .nav>li:first-child>a {
  padding-left: 0;
}

.posts--filter-bar .nav>li:last-child>a {
  padding-right: 0;
}

.posts--filter-bar .nav>li:last-child>a:before {
  border-right-width: 0;
}

.posts--filter-bar.style--2 .nav>li>a {
  padding: 13px 20px 7px;
}

.posts--filter-bar.style--2 .nav>li>a:before,.posts--filter-bar.style--5 .nav>li>a:before {
  border-width: 1px 1px 0;
}

.posts--filter-bar.style--2 .nav>li+li>a:before {
  border-left-width: 0;
}

.posts--filter-bar.style--3 .nav>li>a>i {
  width: 35px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  background-color: #da0000;
  line-height: 35px;
}

.posts--filter-bar.style--4 .nav>li>a>i {
  width: 35px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #ddd;
  line-height: 33px;
}

.posts--filter-bar.style--4 .nav>li>a:hover>i {
  color: #fff;
  background-color: #da0000;
  border-color: #da0000;
}

.posts--filter-bar.style--5 .nav>li>a {
  padding: 13px 20px 7px;
  font-size: 0;
  line-height: 0;
}

.posts--filter-bar.style--5 .nav>li+li>a {
  margin-left: -1px;
}

.posts--filter-bar.style--5 .nav>li>a>i,.posts--filter-bar.style--5 .nav>li>a>span {
  font-size: 18px;
  line-height: 28px;
}

.posts--filter-bar.style--5 .nav>li>a>i {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 0;
}

.news--ticker {
  margin-top: 30px;
}

.news--ticker .title {
  float: left;
  padding: 11px 30px 13px;
  color: #fff;
  background-color: #a70303;
}

.news--ticker .title h2 {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.news--ticker .title span {
  color: #ccc;
  font-size: 14px;
  line-height: 24px;
}

.news-updates--list {
  direction: ltr;
  position: relative;
  max-height: 50px;
  padding: 0 12px;
  color: #000;
  background-color: #e5e5e5;
  overflow: hidden;
  z-index: 0;
}

.news-updates--list:after,.news-updates--list:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 12px;
  background-color: #e5e5e5;
  z-index: 1;
}

.news-updates--list:after {
  left: auto;
  right: 0;
}

.news--ticker .title,.news-updates--list .nav {
  font-size: 0;
  line-height: 0;
}

.news-updates--list .nav li {
  display: inline-block;
}

.news-updates--list .nav li+li {
  margin-left: 30px;
}

.news-updates--list .nav li .h3 {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
}

.news-updates--list .nav li .h3 a {
  display: block;
  padding: 13px 0;
}

.main--breadcrumb {
  margin-top: 23px;
}

.main--breadcrumb .breadcrumb {
  padding: 0;
  color: #555;
  background-color: transparent;
  border-radius: 0;
  font-size: 0;
  line-height: 0;
}

.main--breadcrumb .breadcrumb .active,.page--title .h2 span {
  color: #da0000;
}

.main--breadcrumb .breadcrumb li:before {
  padding: 0 3px 0 4px;
  font-size: 14px;
  line-height: 24px;
}

.main--breadcrumb .breadcrumb li a,.main--breadcrumb .breadcrumb li span,.news-updates--list .nav li,.product--item .rating .nav li i,.product--summery .rating .nav>li>i {
  font-size: 14px;
  line-height: 24px;
}

.page--title .h2 {
  margin-top: -9px;
  margin-bottom: 0;
  color: #222;
  font-weight: 600;
}

.bg--color-1 .page--title .h2,.bg--color-2 .page--title .h2 {
  color: #fff;
}

.page--title p {
  margin-top: 15px;
  margin-bottom: -5px;
}

.page--title .action {
  margin-top: 26px;
}

.page--title.lined .h2 {
  padding-bottom: 11px;
}

.page--title.lined .h2:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 60px;
  height: 2px;
  margin: 0 auto;
  background-color: #da0000;
}

.post--items-title {
  padding: 12px 57px 13px 0;
  border-top: 2px solid #da0000;
}

.post--items-title .h4 {
  position: relative;
  margin: 0;
  color: #da0000;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 0;
}

.post--items-title .icon,.post--items-title .nav {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -15px;
  color: #999;
  font-size: 0;
  line-height: 0;
}

.post--items-title .nav a {
  padding: 0;
  font-size: 16px;
  line-height: 26px;
}

.post--items-title .icon {
  margin-top: -14px;
  font-size: 16px;
  line-height: 26px;
}

.page--title.lined .h2,.post--item,.post--items,.post--items-title {
  position: relative;
  z-index: 0;
}

.post--items.post--items-1 {
  margin-top: -15px;
}

.main--breadcrumb+.post--items.post--items-1 {
  margin-top: 0;
  padding-top: 10px;
}

.comment--content:after,.comment--content:before,.post--content:after,.post--content:before,.post--item.post--layout-3:after,.post--item.post--layout-3:before,.post--item.post--layout-4:after,.post--item.post--layout-4:before {
  content: " ";
  display: table;
}

.comment--content:after,.post--content:after,.post--item.post--layout-3:after,.post--item.post--layout-4:after {
  clear: both;
}

.post--items.post--items-3>.nav>li+li,.post--items.post--items-4>.nav>li>.nav>li+li {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #c4c4c4;
}

.post--items.post--items-5>.nav>li+li {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #eee;
}

.post--items.post--items-1 .post--item {
  margin-top: 15px;
}

.post--items hr.divider {
  margin: 15px 0;
}

.post--items hr.divider--25 {
  margin: 25px 0;
}

.post--items .preloader {
  display: none;
}

.post--img {
  position: relative;
}

.post--single .post--info+.post--img {
  margin-top: 21px;
}

.post--img .thumb {
  position: relative;
  z-index: 0;
}

.post--item.post--layout-3 .post--img .thumb {
  float: left;
  max-width: 100px;
  margin-right: 20px;
}

.post--item.post--layout-4 .post--img .thumb {
  float: left;
  margin-right: 20px;
  max-width: 150px;
}

.post--img a,.post--item.post--layout-1.post--title-largest .post--img span.thumb {
  display: block;
}

.post--item.post--layout-1 .post--img .thumb:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  background: -webkit-linear-gradient(top,#fff 0%,#000 100%);
  background: linear-gradient(to bottom,#fff 0%,#000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
  opacity: .35;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
  -webkit-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
  z-index: 1;
}

.post--item.post--type-audio .thumb:after,.post--item.post--type-video .thumb:after {
  content: "\f04b";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -25px;
  padding: 3px 19px 3px 20px;
  color: #fff;
  background-color: #000;
  border-radius: 10px;
  font-family: "FontAwesome";
  text-align: center;
  -webkit-transition: color .25s,background-color .25s ease-in-out;
  transition: color .25s,background-color .25s ease-in-out;
  z-index: 1;
}

.post--item.post--type-audio .thumb:after {
  margin-left: -17.5px;
  padding: 3px 9px 4px 11px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #000;
}

.post--item.post--type-video:hover .thumb:after {
  background-color: #c21418;
}

.post--item.post--type-audio:hover .thumb:after {
  border-color: #c21418;
}

.post--item.post--type-video.post--layout-3 .thumb:after {
  margin-top: -10px;
  margin-left: -15px;
  padding: 6px 11px 6px 13px;
  font-size: 8px;
  line-height: 8px;
}

.post--item.post--type-audio.post--layout-3 .thumb:after {
  margin-top: -10px;
  font-size: 8px;
  line-height: 8px;
}

.post--item.post--type-video.post--layout-4 .thumb:after {
  margin-top: -10px;
  margin-left: -15px;
  padding: 6px 11px 6px 13px;
  font-size: 8px;
  line-height: 8px;
}

.post--item.post--type-audio.post--layout-4 .thumb:after {
  margin-top: -10px;
  font-size: 8px;
  line-height: 8px;
}

.post--item.post--type-audio.post--layout-3 .thumb:after,.post--item.post--type-audio.post--layout-4 .thumb:after {
  margin-left: -10.5px;
  padding: 4px 5px 5px 6px;
  border-radius: 50%;
}

.post--img .thumb img {
  width: 100%;
}

.post--img a.cat {
  position: absolute;
  top: 15px;
  left: 15px;
  padding: 0 10px;
  color: #fff;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  z-index: 1;
}

.post--img a.cat:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: .8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  -webkit-transition: background-color .25s ease-in-out;
  transition: background-color .25s ease-in-out;
  z-index: -1;
}

.post--img .icon:focus,.post--img .icon:hover,.post--img a.cat:focus,.post--img a.cat:hover {
  color: #fff;
}

.post--img .icon:before,.post--img a.cat:hover:before {
  background-color: #da0000;
}

.post--img .icon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 36px;
  color: #fff;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  z-index: 1;
}

.post--img .icon:before {
  content: " ";
  -webkit-transition: background-color .25s ease-in-out;
  transition: background-color .25s ease-in-out;
  z-index: -1;
}

.post--img .icon:hover:before,.product--item .img .actions .nav>li>a:hover {
  background-color: #000;
}

.post--map {
  color: #fff;
}

.post--img .icon:before,.post--item.post--layout-1 .post--map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.post--map .btn-link {
  display: inline-block;
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 10px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 22px;
  z-index: 1;
}

.post--map .btn-link i {
  margin-right: 15px;
}

.post--map .map--wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: opacity,visibility,-webkit-transform;
  transition-property: opacity,visibility,transform;
  transition-property: opacity,visibility,transform,-webkit-transform;
  -webkit-transition-duration: .25s,.25s,.55s;
  transition-duration: .25s,.25s,.55s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  z-index: 2;
}

.post--map .btn-link.active+.map--wrapper {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.post--map .map--wrapper>div {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  background-color: #f8f8f8;
}

.post--cats .nav,.post--tags .nav {
  margin: 18px -5px 11px;
  color: #999;
  font-size: 0;
  line-height: 0;
}

.post--cats .nav {
  margin-top: -12px;
}

.post--img+.post--cats .nav {
  margin-top: 18px;
}

.post--tags .nav {
  margin-top: 8px;
}

.post--cats .nav li,.post--tags .nav li {
  display: inline-block;
  margin: 10px 5px 0;
  vertical-align: middle;
}

.post--cats .nav li span,.post--tags .nav li span {
  color: #777;
  font-size: 16px;
  line-height: 26px;
}

.post--cats .nav li a,.post--tags .nav li a {
  padding: 0 9px;
  border: 1px solid #999;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
}

.post--cats .nav li a:hover,.post--tags .nav li a:hover {
  color: #fff;
  background-color: #da0000;
  border-color: #da0000;
}

.post--item.post--layout-1 .post--info {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 15px;
  color: #fff;
  z-index: 1;
}

.post--item.post--layout-1 .post--info-vc {
  top: 15px;
}

.post--item.post--layout-1 .post--map+.post--info {
  bottom: 41px;
}

.post--item.post--layout-2 .post--info {
  margin-top: 7px;
}

.post--info .meta {
  margin: 0 -7.5px;
  color: #999;
  font-size: 0;
  line-height: 0;
}

.post--item.post--layout-1 .post--info .meta {
  color: inherit;
}

.post--item.post--layout-3 .post--info,.post--item.post--layout-4 .post--info {
  overflow: hidden;
}

.post--info .meta>li {
  display: inline-block;
  margin: 0 7.5px;
}

.post--item .row .post--info .meta,.post--item.post--layout-3 .post--info .meta,.post--item.post--layout-4 .post--info .meta {
  margin-top: -7px;
}

.list--widget-2 .post--item.post--layout-3 .post--info .meta,.list--widget-2 .post--item.post--layout-4 .post--info .meta {
  margin-top: 0;
}

.post--info .meta>li>a,.post--info .meta>li>span {
  padding: 0;
  font-size: 14px;
  line-height: 24px;
}

.post--items.post--items-2 .post--item.post--layout-2 .post--info .title {
  margin-bottom: -4px;
}

.post--single .post--info .title {
  margin-top: 4px;
}

.post--info .title .h4 {
  margin: 0;
  color: #222;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}

.post--item.post--layout-1 .post--info .title .h4 {
  margin-bottom: -3px;
  color: inherit;
  font-size: 14px;
  line-height: 20px;
}

.post--items.post--items-1 .post--item .post--info .title .h4 {
  font-weight: 600;
}

.post--item.post--title-large .post--info .title .h4 {
  font-size: 16px;
  line-height: 26px;
}

.post--item.post--title-larger .post--info .title .h4 {
  font-size: 18px;
  line-height: 26px;
}

.post--item.post--title-largest .post--info .title .h4 {
  font-size: 36px;
  line-height: 46px;
}

.post--item.post--layout-2 .post--info .title .h4 {
  margin-top: 1px;
  font-weight: 700;
}

.post--single .post--info .title .h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}

.post--item .post--info .title .h4 a {
  display: inline;
}

.post--item .post--content {
  margin-top: 8px;
}

.post--single .post--content {
  margin-top: 14px;
}

.post--single .post--img+.post--content {
  margin-top: 23px;
}

.comment--content p,.comment--content ul,.post--single .post--content p,.post--single .post--content ul {
  margin-bottom: 10px;
}

.comment--content blockquote,.post--content blockquote {
  position: relative;
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 34px;
  padding-right: 34px;
  color: #999;
  font-style: italic;
  border-left-width: 0;
}

.comment--content blockquote:before,.post--content blockquote:before,.post--nav:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  background-color: #f2f3f5;
}

.comment--content blockquote footer,.post--content blockquote footer {
  position: relative;
  padding-bottom: 6px;
  color: #222;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
}

.comment--content blockquote footer:before,.post--content blockquote footer:before {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 3px;
  background-color: #f2f3f5;
}

.post--content .list {
  padding-left: 0;
  font-size: 16px;
  line-height: 26px;
  list-style: none;
}

.post--content .list li:before {
  content: "\f105";
  margin-right: 8px;
  font-family: "FontAwesome";
}

.post--content .list li+li {
  margin-top: 5px;
}

.checkout--billing-info .table tbody tr td+td,.checkout--billing-info .table tbody tr th+th,.post--content .img-caption,.post--nav>.nav>li+li {
  text-align: right;
}

.post--item .post--action {
  margin-top: 5px;
  margin-bottom: -7px;
  color: #222;
}

.post--social {
  margin-top: 10px;
  font-size: 0;
  line-height: 0;
}

.post--tags+.post--social {
  margin-top: 18px;
}

.post--social .title {
  min-width: 17px;
  margin-top: 10px;
  margin-right: 5px;
  color: #777;
  font-size: 16px;
  line-height: 26px;
}

.post--social .social--widget.style--4,.post--social .title {
  display: inline-block;
  vertical-align: middle;
}

.post--social .social--widget.style--4 .nav {
  padding: 0;
  background-color: transparent;
}

.post--social .social--widget.style--4 .nav>li {
  margin: 5px 5px 0;
}

.post--author-info {
  position: relative;
  min-height: 200px;
  margin: 30px 0;
  padding: 27px 30px 24px 180px;
  border: 1px solid #eee;
}

.post--author-info .img {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  padding: 30px;
  color: #222;
  background-color: #f2f3f5;
}

.post--author-info .img img {
  display: block;
  max-width: 90px;
  margin: 0 auto;
}

.post--author-info .img .name {
  margin-top: 7px;
}

.post--author-info .info {
  overflow: hidden;
}

.post--author-info .info .h4 {
  margin: 0;
  color: #222;
  font-weight: 600;
}

.post--author-info .info .content,.product--details .content p+table {
  margin-top: 20px;
}

.post--author-info .info .social {
  margin: 9px -8px 0;
  color: #999;
}

.post--author-info .info .social>li {
  float: left;
}

.post--author-info .info .social>li>a {
  padding: 8px 8px 0;
}

.post--nav,.product--items {
  position: relative;
  z-index: 0;
}

.post--nav:before {
  top: 30px;
  right: 0;
  bottom: 34px;
  width: 2px;
  margin: 0 auto;
  background-color: #eee;
  z-index: -1;
}

.post--nav .post--img .thumb {
  display: inline-block;
  max-width: 80px;
  margin-bottom: 8px;
}

.post--nav .post--img .thumb:after,.post--nav .post--img .thumb:before {
  position: absolute;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
  z-index: 1;
}

.post--nav .post--img .thumb:before {
  right: 0;
  bottom: 0;
  background-color: #000;
  content: " ";
  top: 0;
  left: 0;
}

.post--img .icon:before,.post--nav .post--img .thumb:hover:before {
  opacity: .8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}

.post--nav .post--img .thumb:after {
  content: "\f177";
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -8px;
  color: #fff;
  font-family: "FontAwesome";
  font-size: 16px;
  line-height: 26px;
}

.post--nav .post--img .thumb:hover:after,.product--item .img:hover .actions,.product--item .img:hover:before {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.post--nav>.nav>li+li .post--img .thumb:after {
  content: "\f178";
}

.checkout--billing-info .checkbox>label>input,.checkout--billing-info .panel-group .panel-title label input,.checkout--info-form .checkbox input,.login--form .checkbox input,.product--items .preloader {
  display: none;
}

.product--item {
  padding: 0 0 9px;
  -webkit-transition: box-shadow .25s ease-in-out,padding .25s ease-in-out;
  transition: box-shadow .25s ease-in-out,padding .25s ease-in-out;
}

.product--item:hover {
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 1px 10px rgba(0,0,0,.1);
}

.product--item .img {
  position: relative;
  margin: 0 0 12px;
  -webkit-transition: margin .25s ease-in-out;
  transition: margin .25s ease-in-out;
  z-index: 0;
}

.product--item:hover .img {
  margin-left: -15px;
  margin-right: -15px;
}

.product--img-gallery .nav>li>a:before,.product--item .img:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  background: -webkit-linear-gradient(top,#fff 0%,#000 100%);
  background: linear-gradient(to bottom,#fff 0%,#000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
  z-index: 1;
}

.product--item .img:before {
  right: 0;
  bottom: 0;
  background: #fff;
}

.product--item .img .actions {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
  z-index: 1;
}

.product--item .img img {
  width: 100%;
}

.product--item .img .actions .btn {
  margin: 7.5px;
  padding-left: 10px;
  padding-right: 10px;
}

.product--item .img .actions .btn .fa {
  margin-right: 8px;
}

.product--item .img .actions .nav {
  font-size: 0;
  line-height: 0;
}

.product--item .img .actions .nav>li {
  display: inline-block;
  margin: 7.5px;
}

.product--item .img .actions .nav>li>a {
  min-width: 30px;
  padding: 0;
  color: #fff;
  background-color: #da0000;
  font-size: 14px;
  line-height: 30px;
}

.product--item .title .h5 {
  margin: 0;
  color: #222;
  font-weight: 700;
}

.product--item .price {
  margin-top: 5px;
}

.product--item .price .del {
  margin-right: 15px;
  color: #999;
}

.product--item .rating {
  color: #999;
  -webkit-transition: color .25s;
  transition: color .25s;
}

.contact--cats .nav>li>a:hover .title .h5,.product--item:hover .rating {
  color: #da0000;
}

.product--item .rating .nav {
  margin: 4px -2px 0;
  font-size: 0;
  line-height: 0;
}

.product--item .rating .nav li {
  display: inline-block;
  margin: 0 2px;
}

.product--img-gallery .nav,.product--img-gallery .nav>li {
  margin-top: 15px;
}

.product--img-gallery .nav>li>a {
  padding: 0;
}

.product--img-gallery .nav>li>a:before {
  width: 100%;
  height: 100%;
}

.cart--items .table tbody tr:hover td .img:before,.product--img-gallery .nav>li.active>a:before {
  opacity: .35;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
}

.product--summery .title .h4 {
  margin: -3px 0 0;
  color: #222;
  font-weight: 600;
}

.product--summery .rating {
  margin-top: 21px;
  color: #999;
  font-size: 0;
  line-height: 0;
}

.product--summery .rating .nav {
  display: inline-block;
  margin-right: 20px;
  color: #da0000;
}

.product--summery .rating .nav>li {
  display: inline-block;
}

.product--summery .rating .nav>li+li {
  margin-left: 3px;
}

.product--summery .meta>li,.product--summery .rating>a {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
}

.product--summery .price {
  margin-top: 15px;
}

.product--summery .price span {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

.product--summery .price span.del {
  margin-right: 10px;
  color: #999;
}

.product--summery .note {
  margin-top: 14px;
  margin-bottom: 0;
  color: #999;
}

.product--summery .note strong {
  margin-right: 4px;
  color: #222;
}

.product--summery .description,.product--summery .note+.note {
  margin-top: 16px;
}

.product--summery .cart,.product--summery .meta {
  margin-top: 13px;
  font-size: 0;
  line-height: 0;
}

.product--summery .cart .btn,.product--summery .cart .quantity {
  margin-top: 10px;
  margin-right: 10px;
}

.product--summery .meta {
  margin-top: 21px;
  color: #999;
}

.product--summery .meta>li {
  margin-top: 5px;
  line-height: 18px;
}

.product--summery .meta>li>span {
  color: #222;
  font-weight: 600;
}

.product--summery .meta>li>a {
  display: inline-block;
  padding: 0;
}

.product--summery .meta.tag>li>a {
  border-bottom: 1px solid #bbb;
}

.product--summery .meta.tag>li>a:hover {
  border-color: inherit;
}

.product--summery .meta>li+li {
  margin-left: 5px;
}

.product--summery .meta.social>li+li {
  margin-left: 15px;
  padding-right: 15px;
  border-right: 1px solid #ccc;
}

.product--summery .meta.social>li:last-child {
  border-right-width: 0;
}

.product--summery .meta.social>li>a:after,.product--summery .meta.tag>li>a:after,.product--summery .meta>li:last-child>a:after {
  display: none;
}

.product--single .tab-nav {
  display: inline-block;
  color: #999;
  font-size: 0;
  line-height: 0;
  font-weight: 600;
}

.product--single .tab-nav>li {
  display: inline-block;
}

.product--single .tab-nav>li>a {
  padding: 6px 30px;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  border-color: #da0000;
  font-size: 18px;
  line-height: 28px;
}

#backToTop a:hover,.product--single ul.tab-nav>li.active>a,.product--single ul.tab-nav>li>a:hover {
  color: #fff;
  background-color: #da0000;
}

.product--single .tab-nav>li+li>a {
  border-left-width: 0;
}

.product--single .tab-nav>li:last-child>a {
  border-right-width: 1px;
}

.product--details .content table tr td {
  padding: 13px 20px;
  border: 1px solid #eee;
}

.contact--cats .nav {
  margin: 0;
}

.contact--cats .nav>li {
  padding: 18px 18px 14px;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: #eee;
}

.contact--cats .nav>li:nth-child(1),.contact--cats .nav>li:nth-child(2),.contact--cats .nav>li:nth-child(3) {
  margin-top: 0;
  padding-top: 0;
  border-top-width: 0;
}

.contact--cats .nav>li:nth-child(3n) {
  padding-right: 0;
  border-right-width: 0;
}

.contact--cats .nav>li:first-child,.contact--cats .nav>li:nth-child(3n)+li {
  padding-left: 0;
}

.contact--cats ul.nav>li>a {
  padding: 0;
  color: inherit;
}

.contact--cats .icon {
  margin-bottom: 22px;
}

.contact--cats .title .h5 {
  font-size: 16px;
  line-height: 26px;
}

.contact--cats .content {
  margin-top: 16px;
}

.contributor--item {
  padding: 30px 20px;
  border: 1px solid #eee;
  text-align: center;
}

.contributor--item .img {
  max-width: 80px;
  margin: 0 auto 17px;
}

.contact--cats .title .h5,.contributor--item .name .h4 {
  margin: 0;
  color: #222;
  font-weight: 600;
  -webkit-transition: color .25s;
  transition: color .25s;
}

.contributor--item .desc {
  margin-top: 10px;
}

.contributor--item .social {
  margin: -1px -8px 13px;
  color: #999;
  font-size: 0;
  line-height: 0;
}

.contributor--item .social>li {
  display: inline-block;
}

.contributor--item .social>li>a {
  padding: 8px 8px 0;
  font-size: 14px;
  line-height: 24px;
}

.contributor--item .action {
  margin-top: 11px;
}

.contributor--item.style--2,.contributor--item.style--3 {
  -webkit-transition: box-shadow .25s ease-in-out;
  transition: box-shadow .25s ease-in-out;
}

.contributor--item.style--2 {
  padding-bottom: 20px;
}

.contributor--item.style--2:hover {
  box-shadow: 0 1px 10px rgba(0,0,0,.1);
}

.contributor--item.style--2 .img {
  position: relative;
  max-width: none;
  margin: -31px -21px 17px;
  overflow: hidden;
  z-index: 0;
}

.contributor--item.style--2 .img:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
  z-index: 0;
}

.contributor--item.style--2:hover .img:before {
  opacity: .4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
}

.contributor--item.style--2 .social {
  position: absolute;
  top: 50%;
  left: -60px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 20px 0 16px;
  list-style: none;
  color: #fff;
  font-size: 0;
  line-height: 0;
  text-align: center;
  -webkit-transition-property: left,right;
  transition-property: left,right;
  -webkit-transition-duration: .25s;
  transition-duration: .25s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  z-index: 1;
}

.contributor--item.style--2:hover .social {
  left: 0;
}

.contributor--item.style--2 .social:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  z-index: 0;
}

.contributor--item.style--2 .social>li {
  display: block;
}

.contributor--item.style--2 .social>li>a {
  display: block;
  padding: 6px 16px 7px;
  font-size: 14px;
  line-height: 24px;
}

.contributor--item.style--2 .social>li>a:hover,.contributor--item.style--3 .social>li>a:hover {
  color: #222;
}

.contributor--item.style--3 {
  position: relative;
  padding: 0 0 60px;
  border-width: 0;
  overflow: hidden;
  z-index: 0;
}

.contributor--item.style--3:hover {
  box-shadow: 0 3px 20px rgba(0,0,0,.15);
}

.contributor--item.style--3 .img {
  margin: 0;
  max-width: none;
}

.contributor--item.style--3 .info {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 17px 20px 20px;
  border-style: solid;
  border-width: 0 1px 1px;
  -webkit-transition: border-color .25s,background-color .25s ease-in-out;
  transition: border-color .25s,background-color .25s ease-in-out;
}

.contributor--item.style--3:not(:hover) .info {
  color: #999;
  background-color: #fff;
  border-color: #eee;
}

.contributor--item.style--3:hover .name .h4 {
  color: #fff;
}

.contributor--item.style--3 .social {
  margin-top: 4px;
  color: inherit;
}

.contributor--item.style--3 .action {
  margin-bottom: -83px;
  -webkit-transition: margin-bottom .45s ease-in-out;
  transition: margin-bottom .45s ease-in-out;
}

.contributor--item.style--3:hover .action {
  margin-bottom: 0;
}

.contributor--item.style--3:hover .action .btn {
  border-color: #fff;
}

.contributor--item.style--3:hover .action .btn:hover {
  color: #222;
  background-color: #fff;
}

.contributor--item.style--4 {
  position: relative;
  padding: 0;
  border-width: 0;
  overflow: hidden;
  z-index: 0;
}

.contributor--item.style--4 .img {
  max-width: none;
  margin: 0;
}

.contributor--item.style--4 .info {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  height: 53px;
  padding: 17px 15px 20px;
  -webkit-transition: height .55s ease-in-out,padding-top .25s ease-in-out;
  transition: height .55s ease-in-out,padding-top .25s ease-in-out;
  overflow: hidden;
  z-index: 1;
}

.contributor--item.style--4:hover .info {
  padding-top: 37px;
  height: 100%;
}

.contributor--item.style--4 .info:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: .92;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=92)";
  -webkit-transition: top .55s ease-in-out;
  transition: top .55s ease-in-out;
  z-index: -1;
}

.contributor--item.style--4:hover .info:before {
  top: 20px;
}

.contributor--item.style--4 .desc {
  margin-top: 15px;
}

.contributor--item.style--4 .action {
  margin-top: 16px;
}

.info--block .icon {
  font-size: 40px;
  line-height: 40px;
}

.info--block .title .h5 {
  margin: 0;
  color: #222;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.info--block .title {
  margin-top: 12px;
}

.info--block .content {
  margin-top: 6px;
}

.portfolio--item .img {
  box-shadow: 0 2px 5px rgba(0,0,0,.1);
  -webkit-transition: box-shadow .25s ease-in-out;
  transition: box-shadow .25s ease-in-out;
}

.portfolio--item:hover .img {
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
}

.portfolio--item .title {
  margin-top: 16px;
}

.portfolio--item .title .h4 {
  margin: 0;
  color: #222;
  font-weight: 600;
}

.bg--color-1 .portfolio--item .title .h4 .btn-link,.bg--color-1 .portfolio--item:hover .title .h4 .btn-link,.bg--color-2 .portfolio--item .title .h4 .btn-link,.bg--color-2 .portfolio--item:hover .title .h4 .btn-link {
  color: #fff;
}

.portfolio--item:hover .title .h4 .btn-link {
  color: #da0000;
}

.pricing--img {
  position: relative;
  z-index: 0;
}

.pricing--img.layout--2 {
  padding: 0 30px;
}

.pricing--img.layout--2 img,.pricing--img.layout--3 img,.pricing--img.layout--4 img {
  box-shadow: 0 0 35px rgba(0,0,0,.15);
}

.pricing--img.layout--2 img+img {
  position: absolute;
  top: 30px;
  left: 0;
  max-width: 120px;
}

.pricing--img.layout--2 img+img+img {
  top: auto;
  left: auto;
  right: 0;
  bottom: 30px;
}

.pricing--img.layout--3 img+img {
  margin-top: 20px;
}

.pricing--img.layout--4 {
  padding: 45px 110px 50px 90px;
}

.pricing--img.layout--4:before {
  content: "\f04b";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 50px;
  margin: -15px auto 0;
  color: #fff;
  background-color: #da0000;
  border-radius: 10px;
  font-family: "FontAwesome";
  line-height: 30px;
  text-align: center;
  -webkit-transition: background-color .25s ease-in-out;
  transition: background-color .25s ease-in-out;
  z-index: 1;
}

.pricing--img.layout--4 img+img {
  position: absolute;
  top: 75px;
  left: 0;
  max-width: 120px;
}

.pricing--img.layout--4 img+img+img {
  top: 0;
  left: auto;
  right: 80px;
  max-width: 80px;
}

.pricing--img.layout--4 img+img+img+img {
  top: auto;
  right: 0;
  bottom: 120px;
  max-width: 100px;
}

.pricing--img.layout--4 img+img+img+img+img {
  left: 0;
  bottom: 0;
}

.pricing--content .price-tag {
  margin-bottom: 25px;
  font-size: 36px;
  line-height: 46px;
}

.pricing--content .price-tag p {
  display: inline-block;
  padding: 29px 40px 35px;
  background-color: #fff;
  box-shadow: 0 1px 20px rgba(0,0,0,.15);
}

.pricing--content .price-tag strong {
  color: #222;
  font-size: 48px;
  line-height: 58px;
}

.pricing--content .price-tag strong+sub:before {
  content: "/";
  position: relative;
  top: -8px;
  color: #222;
  font-size: 48px;
  line-height: 58px;
  font-weight: 600;
}

.pricing--content .title .h3 {
  margin-bottom: 17px;
  color: #222;
  font-weight: 600;
  text-transform: uppercase;
}

.pricing--content .features {
  margin-top: 17px;
}

.pricing--content .features .nav>li>span {
  position: relative;
  padding: 1px 0 1px 20px;
}

.faq--item .panel-title a:before,.pricing--content .features .nav>li>span:before {
  content: "\f046";
  position: absolute;
  left: 0;
  color: #222;
  font-family: "FontAwesome";
}

.pricing--content .action {
  margin-top: 25px;
}

.faq--items {
  margin-bottom: 6px;
}

.faq--item {
  background-color: transparent;
  box-shadow: none;
}

.faq--items .faq--item+.faq--item {
  margin-top: 6px;
}

.faq--item .panel-heading {
  padding: 0;
}

.faq--item .panel-title a {
  display: block;
  position: relative;
  padding-left: 24px;
  color: #da0000;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.faq--item .panel-title a:before {
  content: "\f106";
}

.faq--item .panel-title a.collapsed {
  color: #777;
}

.faq--item .panel-title a.collapsed:before {
  content: "\f107";
}

#stickySocial .nav>li+li>a>i,.faq--item .panel-heading+.panel-collapse .panel-body {
  border-top-width: 0;
}

.faq--item .panel-body {
  margin-top: 16px;
  margin-left: 8px;
  margin-bottom: 4px;
  padding: 3px 10px 4px;
  color: #999;
  border-left: 1px solid #ddd;
}

.login--form,.login--form:before {
  background-color: rgba(255,255,255,.1);
}

.login--form {
  position: relative;
  max-width: 670px;
  margin: 70px auto 0;
  padding: 112px 30px 186px;
  text-align: center;
  z-index: 0;
}

.login--form:after,.login--form:before {
  content: " ";
  position: absolute;
  top: -70px;
  z-index: 1;
}

.login--form:before {
  left: 0;
  right: 70px;
  height: 70px;
}

.login--form:after {
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 35px;
  border-color: transparent transparent rgba(255,255,255,.18) rgba(255,255,255,.18);
  box-shadow: -5px 7px 15px rgba(0,0,0,.2);
}

.login--form .title {
  margin-bottom: 69px;
}

.login--form .title .h1 {
  margin: 0;
  font-weight: 600;
}

.login--form .title p {
  margin-top: 12px;
  color: #ccc;
  font-size: 16px;
  line-height: 26px;
}

.login--form form {
  max-width: 470px;
  margin: 0 auto;
}

.login--form .checkbox label span,.login--form .form-group {
  margin-bottom: 0;
}

.login--form .form-group+.form-group {
  margin-top: 23px;
}

.login--form label {
  display: block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: left;
}

.login--form label span {
  display: block;
  margin-bottom: 8px;
  color: #999;
}

.login--form .form-control {
  color: #fff;
  border-color: #999;
}

.coming-soon--content .subscribe--widget input:focus+.input-group-btn:before,.login--form .form-control:focus {
  border-color: #da0000;
}

.login--form .checkbox {
  margin: 23px 0 0;
}

.login--form .checkbox label {
  padding-left: 20px;
}

.login--form .checkbox span:before {
  content: " ";
  position: absolute;
  top: 7px;
  left: 0;
  width: 10px;
  height: 10px;
  border: 1px solid;
}

.login--form .checkbox span:after {
  content: "\f00c";
  position: absolute;
  top: -.5px;
  left: 1.5px;
  font-family: "FontAwesome";
  font-size: 8px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity .25s;
  transition: opacity .25s;
}

.checkout--billing-info .checkbox>label>input:checked+span:after,.checkout--billing-info .panel-group .panel-title label input:checked+span:after,.checkout--info-form .checkbox input:checked+span:after,.login--form .checkbox input:checked+span:after {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.login--form .btn {
  margin-top: 23px;
  padding-top: 5px;
  padding-bottom: 7px;
}

.login--form .help-block {
  margin-top: 7px;
  color: #999;
}

.coming-soon--content {
  position: relative;
  margin-top: 70px;
  padding: 10px 30px 74px;
  color: #fff;
  background-color: rgba(255,255,255,.2);
  z-index: 0;
}

.coming-soon--content:before {
  content: " ";
  position: absolute;
  top: -70px;
  left: 0;
  right: 70px;
  height: 70px;
  background-color: rgba(255,255,255,.2);
  z-index: 1;
}

.coming-soon--content:after {
  content: " ";
  position: absolute;
  top: -70px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 35px;
  border-color: transparent transparent rgba(255,255,255,.28) rgba(255,255,255,.28);
  box-shadow: -5px 7px 15px rgba(0,0,0,.2);
  z-index: 1;
}

.coming-soon--content .logo {
  margin-bottom: 73px;
}

.coming-soon--content .sub-title .h4,.coming-soon--content .title .h2 {
  margin: 0;
  font-weight: 600;
}

.coming-soon--content .countdown {
  margin-top: 22px;
}

.coming-soon--content .sub-title {
  margin-top: 33px;
}

.coming-soon--content .content {
  margin-top: 19px;
  color: #ccc;
}

.coming-soon--content .subscribe--widget {
  margin-top: 31px;
  padding: 0;
  background-color: transparent;
}

.coming-soon--content .subscribe--widget .input-group {
  position: relative;
  padding: 5px;
  z-index: 0;
}

.coming-soon--content .subscribe--widget .form-control {
  height: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #fff;
  background-color: transparent;
}

.coming-soon--content .subscribe--widget .input-group-btn {
  position: static;
}

.coming-soon--content .subscribe--widget .input-group-btn:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #999;
  -webkit-transition: border-color .25s;
  transition: border-color .25s;
  z-index: -1;
}

.coming-soon--content .subscribe--widget .btn {
  padding-top: 1px;
  padding-bottom: 1px;
}

.coming-soon--content .social {
  margin-top: 33px;
}

.coming-soon--content .social p {
  margin-bottom: 9px;
}

.coming-soon--content .social .nav {
  margin: 0 -10px;
  color: #999;
  font-size: 0;
  line-height: 0;
}

.coming-soon--content .social .nav>li {
  display: inline-block;
  margin: 10px 10px 0;
}

.coming-soon--content .social .nav>li>a {
  padding: 0;
  font-size: 14px;
  line-height: 24px;
}

.f0f--content,.f0f--content:before {
  background-color: rgba(255,255,255,.2);
}

.f0f--content {
  position: relative;
  margin-top: 70px;
  padding: 20px 30px 120px;
  color: #fff;
  text-align: center;
  z-index: 0;
}

.f0f--content:after,.f0f--content:before {
  content: " ";
  position: absolute;
  top: -70px;
  z-index: 1;
}

.f0f--content:before {
  left: 0;
  right: 70px;
  height: 70px;
}

.f0f--content:after {
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 35px;
  border-color: transparent transparent rgba(255,255,255,.28) rgba(255,255,255,.28);
  box-shadow: -5px 7px 15px rgba(0,0,0,.2);
}

.f0f--content .title .h1 {
  margin: 0;
  font-size: 120px;
  line-height: 130px;
}

.f0f--content .sub-title {
  margin-top: 5px;
}

.f0f--content .sub-title .h5 {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

.f0f--content .search--widget {
  margin-top: 33px;
  padding: 0;
  box-shadow: none;
}

.f0f--content .search--widget .form-control,.f0f--content .search--widget .input-group-btn {
  color: #fff;
}

.f0f--content .search--widget .input-group-btn:before {
  border-color: #999;
}

.f0f--content .note {
  margin-top: 33px;
}

.f0f--content .action {
  margin-top: 26px;
}

.cart--items .table {
  margin-bottom: 0;
  border-color: #eee;
}

.cart--items .table tbody tr td,.cart--items .table thead tr th {
  border-color: #eee;
  text-align: center;
  vertical-align: middle;
}

.cart--items .table thead tr th {
  padding: 16px 20px;
  color: #222;
  background-color: #eee;
  border-bottom-width: 0;
  font-family: "Lora",serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.cart--items .table tbody tr td {
  padding: 15px;
  border-width: 1px 0;
  font-weight: 600;
}

.cart--items .table tbody tr td .img {
  display: inline-block;
  position: relative;
  z-index: 0;
}

.cart--items .table tbody tr td .img:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-linear-gradient(top,#fff 0%,#000 100%);
  background: linear-gradient(to bottom,#fff 0%,#000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
  z-index: 1;
}

.cart--items .product--quantity .ui-spinner {
  margin-top: 0;
  margin-right: 0;
}

.cart--items .footer {
  padding: 30px 45px;
  border-style: solid;
  border-width: 0 1px 1px;
  border-color: #eee;
}

.cart--items .footer .coupon--code {
  max-width: 410px;
}

.cart--items .footer .coupon--code .form-control {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.cart--total .table {
  margin-bottom: 10px;
  background-color: #f0f0f0;
}

.cart--total .table tr td {
  padding: 6px 20px;
  border-width: 0;
  border-color: #fff;
  font-size: 16px;
  line-height: 28px;
}

.cart--total .table tr+tr td {
  border-top-width: 1px;
}

.checkout--info .title {
  padding: 19px 20px 17px;
  color: #999;
  background-color: #f0f0f0;
}

.checkout--info .title .fa {
  color: #da0000;
  margin-right: 5px;
}

.checkout--info .title a {
  display: inline-block;
  color: #777;
  font-weight: 500;
}

.checkout--billing-info .table tbody tr td span,.checkout--info .title a.active,.checkout--info .title a:hover {
  color: #da0000;
}

.checkout--info-form {
  display: none;
  padding-top: 30px;
}

.checkout--info-form p {
  margin-bottom: 19px;
}

.checkout--info-form p:last-child {
  margin-bottom: 0;
}

.checkout--info-form .form-group {
  margin-bottom: 30px;
}

.checkout--info-form .form-group label span {
  display: inline-block;
}

.checkout--info-form label {
  font-weight: 400;
}

.checkout--info-form .form-control {
  border-color: #eee;
}

.checkout--info-form .form-control:focus {
  border-color: #da0000;
}

.checkout--info-form .submit-btn-group {
  font-size: 0;
  line-height: 0;
}

.checkout--info-form .checkbox {
  display: inline-block;
  margin: 0;
  color: #999;
  font-size: 14px;
  line-height: 24px;
  vertical-align: middle;
}

.checkout--info-form .checkbox span {
  position: relative;
  display: block;
  padding-left: 19px;
}

.checkout--info-form .checkbox span:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  margin-top: -6px;
  border: 1px solid #eee;
}

.checkout--info-form .checkbox span:after {
  content: "\f00c";
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  margin-top: -10px;
  font-family: "FontAwesome";
  font-size: 8px;
  line-height: 18px;
  text-align: center;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity .25s;
  transition: opacity .25s;
}

.checkout--info-form .help-block {
  margin-top: 24px;
  color: #999;
}

.checkout--info-form .help-block a {
  border-bottom: 1px solid;
  line-height: 18px;
}

.checkout--info-form .input-group {
  max-width: 450px;
}

.checkout--info-form .input-group .form-control {
  background-color: #f0f0f0;
}

.checkout--billing-info .form-group {
  margin-bottom: 14px;
}

.checkout--billing-info .form-group.pbottom--60 {
  margin-bottom: 0;
}

.checkout--billing-info label {
  display: block;
  margin-bottom: 0;
  padding-left: 0;
  font-weight: 400;
}

.checkout--billing-info label span {
  display: block;
  margin-bottom: 3px;
  color: #999;
}

.checkout--billing-info .form-control {
  border-color: #eee;
  font-size: 14px;
}

.checkout--billing-info .form-control:focus {
  border-color: #da0000;
}

.checkout--billing-info .checkbox {
  display: inline-block;
  margin: 0;
  color: #999;
  font-size: 14px;
  line-height: 24px;
  vertical-align: middle;
}

.checkout--billing-info .checkbox>label>span {
  position: relative;
  display: block;
  padding-left: 19px;
}

.checkout--billing-info .checkbox>label>span:before,.checkout--billing-info .panel-group .panel-title label span:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  border: 1px solid #eee;
}

.checkout--billing-info .checkbox>label>span:after {
  content: "\f00c";
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  margin-top: -10px;
  font-family: "FontAwesome";
  font-size: 8px;
  line-height: 18px;
  text-align: center;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: opacity .25s;
  transition: opacity .25s;
}

.checkout--billing-info .table tbody tr th {
  color: #222;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.checkout--billing-info .table tbody tr td,.checkout--billing-info .table tbody tr th {
  position: relative;
  padding: 11px 20px 9px;
  background-color: #f9f9f9;
  border-width: 1px 0;
  border-color: #fff;
  z-index: 0;
}

.checkout--billing-info .table tbody tr td+td:before,.checkout--billing-info .table tbody tr th+th:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1px;
  background-color: #eee;
}

.checkout--billing-info .panel-group,.contact--info .content p {
  margin-bottom: 0;
}

.checkout--billing-info .panel-group .panel {
  border: 0;
  box-shadow: none;
}

.checkout--billing-info .panel-group .panel+.panel {
  margin-top: 16px;
}

.checkout--billing-info .panel-group .panel-heading {
  padding: 0;
  border-radius: 0;
}

.checkout--billing-info .panel-group .panel-title label {
  position: relative;
  margin-bottom: 0;
  color: #fd8469;
  cursor: pointer;
}

.checkout--billing-info .panel-group .panel-title label span {
  display: block;
  padding-left: 20px;
  font-family: "Roboto",sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
}

.checkout--billing-info .panel-group .panel-title label span:before {
  margin-top: -6px;
  border-radius: 50%;
}

.checkout--billing-info .panel-group .panel-title label span:after,.counter--item:before {
  content: " ";
  position: absolute;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.checkout--billing-info .panel-group .panel-title label span:after {
  margin-top: -3px;
  border-radius: 50%;
  top: 50%;
  left: 3px;
  width: 4px;
  height: 4px;
  background-color: #999;
  -webkit-transition: opacity .25s;
  transition: opacity .25s;
}

.checkout--billing-info .panel-group .panel-collapse .panel-body {
  border: 0;
  padding: 8px 0 0;
  color: #999;
  font-weight: 300;
}

.counter--item {
  padding: 60px;
}

.counter--item:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  -webkit-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
  z-index: -1;
}

.counter--item.even:before {
  opacity: .05;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=5)";
}

.counter--item:hover:before {
  opacity: .1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}

.counter--item .icon {
  margin-bottom: 17px;
  font-size: 30px;
  line-height: 40px;
}

.counter--item .num,.counter--item .text {
  font-size: 26px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
}

.counter--item .num {
  margin-top: 15px;
  font-size: 36px;
  line-height: 40px;
}

.contact--info .nav>li+li {
  margin-top: 30px;
}

.contact--info .title {
  margin-bottom: 9px;
  color: #222;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.contact--info .title .fa {
  min-width: 16px;
  margin-right: 8px;
}

.contact--info .title .h5 {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

.contact--info .content {
  font-weight: 600;
}

.footer--copyright.bg--color-3,.footer--widgets.bg--color-2 {
  color: #777;
}

#stickySocial .nav>li>a,.counter--item,.footer--copyright {
  position: relative;
  z-index: 0;
}

.footer--copyright .social--bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.footer--copyright .text {
  margin-bottom: 0;
  padding: 27px 0;
  font-size: 16px;
  line-height: 26px;
}

.footer--copyright .nav>li {
  float: left;
}

.footer--copyright .nav>li>a {
  padding: 0;
}

.footer--copyright .links {
  padding: 32px 30px 32px 0;
  font-size: 0;
  line-height: 0;
}

.footer--copyright .links>li+li {
  margin-left: 12px;
  padding-left: 11px;
  border-left: 1px solid;
}

.footer--copyright .links>li>a {
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
}

.footer--copyright .social {
  padding: 28px 0 28px 22px;
}

.footer--copyright .social>li {
  margin: 0 9px;
}

.footer--copyright ul.social>li>a {
  color: #fff;
  -webkit-transition: opacity .25s;
  transition: opacity .25s;
}

.footer--copyright .social>li>a:hover {
  opacity: .5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

#stickySocial {
  position: fixed;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0;
  line-height: 0;
  z-index: 999;
}

#backToTop a,#stickySocial .nav {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}

#stickySocial .nav>li>a {
  padding: 0;
}

#stickySocial .nav>li>a>i {
  display: block;
  width: 42px;
  color: #abc;
  background-color: #f5f6f7;
  border: 1px solid #eee;
  line-height: 42px;
  text-align: center;
  -webkit-transition: color .25s,border-color .25s,background-color .25s ease-in-out;
  transition: color .25s,border-color .25s,background-color .25s ease-in-out;
}

#stickySocial .nav>li>a:hover>i {
  color: #fff;
  background-color: #eee;
}

#backToTop,#stickySocial .nav>li>a>span {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: hidden;
}

#stickySocial .nav>li>a>span {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-right: 10px;
  padding: 0 10px;
  color: #fff;
  background-color: #eee;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  -webkit-transition: opacity .25s ease-in-out,visibility .25s ease-in-out;
  transition: opacity .25s ease-in-out,visibility .25s ease-in-out;
  position: absolute;
  right: 100%;
}

#stickySocial .nav>li>a:hover>span,body.isScrolling #backToTop {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
}

#stickySocial .nav>li>a>span:before {
  content: " ";
  position: absolute;
  top: 50%;
  right: -10px;
  width: 0;
  height: 0;
  margin-top: -4px;
  border-style: solid;
  border-width: 5px;
  border-color: #eee;
  border-top-color: transparent!important;
  border-bottom-color: transparent!important;
}

#stickySocial.sticky--right .nav>li>a>span:before {
  border-right-color: transparent!important;
}

#stickySocial.sticky--left {
  left: 0;
  right: auto;
}

#stickySocial.sticky--left .nav>li>a>span {
  left: 100%;
  right: auto;
  margin-left: 10px;
  margin-right: 0;
}

#stickySocial.sticky--left .nav>li>a>span:before {
  left: -10px;
  right: 0;
  border-left-color: transparent!important;
}

#backToTop {
  position: fixed;
  right: 30px;
  bottom: 50px;
  -webkit-transition-property: opacity,visibility;
  transition-property: opacity,visibility;
  -webkit-transition-duration: .25s;
  transition-duration: .25s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  z-index: 999;
}

#backToTop a {
  display: block;
  width: 50px;
  color: #777;
  background-color: #000;
  font-size: 24px;
  line-height: 50px;
}

/* --------------------------------------------------------EXTRA--------------------------------------------------------------------- */

.phoneblock{
	width:250px;
	float:right;
	color:#666;
	font-size:22px;
	/*border:#000 1px solid;*/
	margin-top:15px;
	text-align:left !important;
}
.phoneblock span{
	font-size:17px;
	color:#000;
}
.phoneblock_left{
	float:left;
	margin-top:5px;
}
.phoneblock_right{
	float:left;
	margin-left:8px;
}
.hiddenclear{
	clear:none;
	display:none;
	visibility:hidden;
}
@media screen and (max-width:991px) {
.phoneblock{
	float:none;
	margin:0 auto;
	width:250px;
	display:block;
}
.hiddenclear{
	clear:both !important;
	display:block !important;
	visibility:visible !important;
}
}
.bigmobileicon{
	display:block;
	visibility:visible;
}
.smallmobileicon{
	display:none;
	visibility:hidden;
}
@media screen and (max-width:526px) {
.bigmobileicon{
	display:none;
	visibility:hidden;
}
.smallmobileicon{
	display:block;
	visibility:visible;
}
.phoneblock{
	width:210px;
	float:none;
	color:#666;
	font-size:16px;
	/*border:#000 1px solid;*/
	margin-top:0 auto;
	text-align:left !important;
	line-height:10px;
}
.phoneblock span{
	font-size:13px;
	color:#000;
	line-height:20px;
	/*display:none;
	visibility:hidden;*/
}	
}



